import React, { useState } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { useUnit } from '../../context/UnitContext';
import { Helmet } from 'react-helmet';
import { styled } from '@mui/system'; // Add this import

const StyledButton = styled(Button)({
  padding: '10px 20px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  backgroundColor: '#2b2d42',
  color: '#f4f1de',
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
  transition: 'background-color 0.3s ease-in-out, transform 0.2s',
  '&:hover': {
    backgroundColor: '#FABC18',
    color: '#2b2d42',
    transform: 'scale(1.15)',
  },
});

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fabc18',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#fabc18',
    },
  },
});

const HydrometerTempCorrection = () => {
  const { unit, switchToImperial, switchToMetric } = useUnit();
  const [gravity, setGravity] = useState('');
  const [tempObserved, setTempObserved] = useState('34'); // Default temperature for observed
  const [tempCalibration, setTempCalibration] = useState('20'); // Default calibration temp
  const [gravityScale, setGravityScale] = useState('gravity'); // Default to gravity
  const [correctedGravity, setCorrectedGravity] = useState('0');
  const [gravityError, setGravityError] = useState(false);

  const handleInputChange = (setter, validator) => (e) => {
    const value = e.target.value;
    setter(value);
    if (validator) {
      setGravityError(!validator(value));
    }
  };

  const correctGravity = (value) => {
    const numValue = parseFloat(value);
    return numValue > 600 ? numValue / 1000 : numValue; // Correct large inputs
  };

  const validateGravity = (value) => {
    const numValue = parseFloat(value);
    return (
      (numValue >= -50 && numValue <= 50) || (numValue >= 700 && numValue <= 1300)
    );
  };

  const calculateCorrection = () => {
    const gravityValue = correctGravity(parseFloat(gravity));
    const tempObs = parseFloat(tempObserved);
    const tempCal = parseFloat(tempCalibration);
    const coefficient = unit === 'Metric' ? 0.000285 : 0.00016;

    const correction = coefficient * (tempObs - tempCal);
    const correctedValue = gravityValue + correction;

    setCorrectedGravity(correctedValue.toFixed(5));
  };

  return (
    <div>
      <Helmet>
        <title>Hydrometer Temperature Correction - Has A Brewery</title>
        <meta name="description" content="Adjust your hydrometer readings for temperature variations accurately. Perfect for homebrewers who want precise measurements!" />
        <meta property="og:title" content="Hydrometer Temperature Correction - Has A Brewery" />
        <meta property="og:description" content="Use our tool to correct hydrometer readings based on temperature differences. Achieve brewing accuracy with temperature-calibrated results." />
        <meta property="og:image" content="/images/hydrotemp-correction-thumbnail.jpg" />
        <meta property="og:url" content="https://hasabrewery.com/tools/calculator/hydrotemp-correction" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Hydrometer Temperature Correction - Has A Brewery" />
        <meta name="twitter:description" content="Adjust your hydrometer readings for temperature variations accurately. Perfect for homebrewers who want precise measurements!" />
        <meta name="twitter:image" content="/images/hydrotemp-correction-thumbnail.jpg" />
      </Helmet>

      <Box sx={{ padding: '20px', minHeight: '100vh', maxWidth: '1200px', display: 'flex', flexDirection: 'column', gap: '20px', backgroundColor: '#FAF9F1' }}>
        {/* Header Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #ccc',
            paddingBottom: '10px',
            flexDirection: { xs: 'column-reverse', sm: 'row' }, // Adjust flex direction based on screen size
            gap: { xs: '10px', sm: '0' }, // Add gap for small screens
            '@media (max-width: 550px)': {
              flexDirection: 'column-reverse', // Dropdown above headline on small screens
              alignItems: 'flex-end', // Align dropdown to the right
            },
            '@media (max-width: 600px) and (min-width: 551px)': {
              flexDirection: 'row', // Keep the dropdown and headline in a row
              justifyContent: 'space-between', // Ensure they are spaced correctly
            },
          }}
        >
          <Typography variant="h3" sx={{ textAlign: 'left' }}>Hydrometer Temperature Correction</Typography>
          <Select
            value={unit}
            onChange={(e) => (e.target.value === 'Metric' ? switchToMetric() : switchToImperial())}
            variant="outlined"
            size="small"
            sx={{ width: '150px', textAlign: 'left' }}
          >
            <MenuItem value="Metric">Metric</MenuItem>
            <MenuItem value="Imperial">Imperial</MenuItem>
          </Select>
        </Box>

        {/* Overview */}
        <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: '16px' }}>
          Ensure accurate brewing measurements by adjusting your hydrometer readings for temperature variations with this essential tool.
          Hydrometers are typically calibrated to a specific temperature, often 20°C (68°F), and readings taken at different temperatures
          can lead to incorrect values. Use this calculator to correct your gravity readings within a reasonable range of temperatures,
          ensuring precise results for your brewing process. For more insights, explore our
          <a href="/tools/calculators/plato-gravity-converter" style={{ color: '#4c9a2a', textDecoration: 'underline' }}> Plato to Gravity Converter </a>
          and our <a href="/tools/calculators/abv" style={{ color: '#4c9a2a', textDecoration: 'underline' }}> ABV Calculator</a>.
          Note that extreme temperature corrections may introduce inaccuracies, so always aim to measure within practical brewing limits.
        </Typography>


        {/* Input Fields */}
        <Box sx={{ display: 'flex', gap: '20px', flex: '1', flexDirection: { xs: 'column', sm: 'row' } }}>
          {/* Sidebar */}
          <Box
            sx={{
              flex: '1 1 30%',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              padding: '10px',
              borderRadius: '8px',
            }}
          >
            {/* Gravity/Plato Picker */}
            <Box sx={{ height: '4px' }} />
            <Select
              value={gravityScale}
              onChange={(e) => setGravityScale(e.target.value)}
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
            >
              <MenuItem value="gravity">Specific Gravity (SG)</MenuItem>
              <MenuItem value="plato">Plato (°P)</MenuItem>
            </Select>
          </Box>

          {/* Main Content */}
          <Box
            sx={{
              flex: '1 1 70%',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Typography variant="body1">
              <b>Units:</b> {unit}; <b>Gravity Scale:</b> {gravityScale}
            </Typography>
            <StyledTextField
              label={gravityScale === 'gravity' ? 'Hydrometer Reading (SG)' : 'Hydrometer Reading (°P)'}
              type="number"
              value={gravity}
              onChange={handleInputChange(setGravity, validateGravity)}
              fullWidth
              variant="outlined"
              error={gravityError}
              helperText={gravityError && 'Value out of range'}
            />
            <StyledTextField
              label={`Observed Temperature (${unit === 'Metric' ? '°C' : '°F'})`}
              type="number"
              value={tempObserved}
              onChange={handleInputChange(setTempObserved)}
              fullWidth
              variant="outlined"
            />
            <StyledTextField
              label={`Calibration Temperature (usually: ${unit === 'Metric' ? '20°C' : '68°F'})`}
              type="number"
              value={tempCalibration}
              onChange={handleInputChange(setTempCalibration)}
              fullWidth
              variant="outlined"
            />
            <StyledButton onClick={calculateCorrection}>
              Calculate
            </StyledButton>
          </Box>
        </Box>


        <Box>
          <Divider sx={{ marginBottom: '20px' }} />
          <Typography variant="h5">Results</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#FAF9F1', fontWeight: 'bold' }}>Metric</TableCell>
                  <TableCell sx={{ color: '#FAF9F1', fontWeight: 'bold' }}>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Adjusted Gravity</TableCell>
                  <TableCell>{parseFloat(correctedGravity).toFixed(3)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>


        <Box sx={{ marginTop: '40px', paddingLeft: '16px' }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              marginBottom: '16px',
              color: '#2b2d42',
              textAlign: 'center',
            }}
          >
            About Hydrometer Temperature Correction
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '16px', textAlign: 'justify' }}>
            Hydrometers are calibrated to measure specific gravity at a standard temperature (commonly 20°C or 68°F). However,
            the liquid's temperature during measurement may vary, requiring a correction to obtain accurate results. This
            calculator uses industry-standard formulas to adjust your readings.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              marginBottom: '8px',
              color: '#2b2d42',
            }}
          >
            Formula Used
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: '16px', textAlign: 'justify', paddingLeft: '16px' }}>
            <strong>Corrected SG = Observed SG + 0.000285 × (Observed Temp - Calibration Temp)</strong> (for Metric units)
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              marginBottom: '8px',
              color: '#2b2d42',
            }}
          >
            Safety Warnings
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '16px', textAlign: 'justify' }}>
            Always handle hot liquids with care to avoid burns. Glass hydrometers can crack if exposed to rapid temperature
            changes. Allow liquids to cool before measurement to ensure safety and preserve your equipment.
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default HydrometerTempCorrection;
