import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
} from '@mui/material';
import Papa from 'papaparse';
import waterProfilesCsv from '../../assets/data/water_profiles.csv';
import { useUnit } from '../../context/UnitContext';
import { styled } from '@mui/system'; // Add this import

const StyledButton = styled(Button)({
  padding: '10px 20px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  backgroundColor: '#2b2d42',
  color: '#f4f1de',
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
  transition: 'background-color 0.3s ease-in-out, transform 0.2s',
  '&:hover': {
    backgroundColor: '#FABC18',
    color: '#2b2d42',
    transform: 'scale(1.15)',
  },
});

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fabc18',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#fabc18',
    },
  },
});

const StyledSelect = styled(Select)({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#fabc18',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#fabc18',
  },
});

const WaterProfiles = () => {
  const { unit, switchToImperial, switchToMetric } = useUnit();
  const [waterProfiles, setWaterProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    Papa.parse(waterProfilesCsv, {
      download: true,
      header: true,
      complete: (result) => {
        setWaterProfiles(result.data);
        setFilteredProfiles(result.data);
      },
    });
  }, []);

  const formatDate = (year, month, day) => {
    if (!year) {
      return "No time stamp for report";
    }
    if (year && !month) {
      return `Report from ${year}`;
    }
    if (year && month && !day) {
      return `Report from ${month} ${year}`;
    }
    if (year && month && day) {
      return `This report is from ${day} ${month} ${year}`;
    }
    return "No time stamp for report";
  };

  useEffect(() => {
    filterProfiles();
  }, [searchQuery, selectedCountry, selectedState, selectedCity]);

  const filterProfiles = () => {
    let filtered = waterProfiles;

    if (searchQuery) {
      filtered = filtered.filter((profile) =>
        profile.city && profile.city.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (selectedCountry) {
      filtered = filtered.filter((profile) => profile.country === selectedCountry);
    }
    if (selectedState) {
      filtered = filtered.filter((profile) => profile.state === selectedState);
    }
    if (selectedCity) {
      filtered = filtered.filter((profile) => profile.city === selectedCity);
    }
    setFilteredProfiles(filtered);
  };

  const clearFilters = () => {
    setSearchQuery('');
    setSelectedCountry('');
    setSelectedState('');
    setSelectedCity('');
  };

  const handleRowClick = (profile) => {
    setSelectedProfile(profile);
    setDetailDialogOpen(true);
  };

  const handleDetailDialogClose = () => {
    setDetailDialogOpen(false);
  };

  const handleSubmitReport = () => {
    setSubmitDialogOpen(true);
  };

  const handleSubmitDialogClose = () => {
    setSubmitDialogOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ padding: '20px', minHeight: '100vh', maxWidth: '1200px', display: 'flex', flexDirection: 'column', gap: '20px', backgroundColor: '#FAF9F1' }}>
      {/* Header Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #ccc',
          paddingBottom: '10px',
        }}
      >
        <Typography variant="h3">Water Profiles</Typography>
        <Select
          value={unit}
          onChange={(e) => (e.target.value === 'Metric' ? switchToMetric() : switchToImperial())}
          variant="outlined"
          size="small"
          sx={{ width: '150px' }}
        >
          <MenuItem value="Metric">Metric</MenuItem>
          <MenuItem value="Imperial">Imperial</MenuItem>
        </Select>
      </Box>

      {/* Introduction */}
      <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: '16px' }}>
        Explore a curated collection of water profiles submitted by passionate homebrewers from around the globe.
        These profiles provide valuable insights into the mineral content and pH of local water sources, helping you optimize
        your brewing process. Each profile is clickable, offering additional details such as submission dates and, when available,
        links to the original source for further verification. Please note that while these profiles are an excellent starting point,
        they are subject to change and may contain errors. We recommend testing your water to ensure accuracy for your specific brewing needs.
      </Typography>


      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        All values shown are in mg/L or PPM.
      </Typography>


      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <StyledTextField
          label="Search City"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          variant="outlined"
          fullWidth
        />
        <Box>
          {/* Country Dropdown */}
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              flexDirection: { xs: 'column', sm: 'row' }, // Stack on small screens
            }}
          >
            <StyledSelect
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
              displayEmpty
              variant="outlined"
              fullWidth
            >
              <MenuItem value="">Show All Countries</MenuItem>
              {[...new Set(waterProfiles.map((profile) => profile.country))].map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </StyledSelect>

            {selectedCountry && (
              <StyledSelect
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
                displayEmpty
                variant="outlined"
                fullWidth
                sx={{
                  borderColor: '#333',
                  color: '#333',
                  marginTop: { xs: '10px', sm: '0' }, // Add margin on small screens
                }}
              >
                <MenuItem value="">Show All States</MenuItem>
                {[...new Set(waterProfiles.filter((profile) => profile.country === selectedCountry).map((profile) => profile.state))].map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
          </Box>

          {/* City Dropdown */}
          {selectedCountry && (
            <Box sx={{ marginTop: '20px' }}>
              <StyledSelect
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                displayEmpty
                variant="outlined"
                fullWidth
                sx={{ borderColor: '#333', color: '#333' }}
              >
                <MenuItem value="">Show All Cities</MenuItem>
                {[...new Set(
                  waterProfiles
                    .filter((profile) =>
                      selectedState
                        ? profile.country === selectedCountry && profile.state === selectedState
                        : profile.country === selectedCountry
                    )
                    .map((profile) => profile.city)
                )].map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </StyledSelect>
            </Box>
          )}
        </Box>


        <Button variant="outlined" onClick={clearFilters} sx={{ alignSelf: 'flex-start', borderColor: '#333', color: '#333' }}>
          Clear Filters
        </Button>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="body1" sx={{ marginTop: '20px', color: '#333' }}>
          {filteredProfiles.length} water profiles found
        </Typography>
        <Select
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
          variant="outlined"
          size="small"
          sx={{ width: '150px', borderColor: '#333', color: '#333' }}
        >
          <MenuItem value={10}>Show 10</MenuItem>
          <MenuItem value={25}>Show 25</MenuItem>
          <MenuItem value={50}>Show 50</MenuItem>
          <MenuItem value={100}>Show 100</MenuItem>
        </Select>
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#5a5a5a' }}>
              <TableCell sx={{ color: "#f4f1de" }}>Country</TableCell>
              <TableCell sx={{ color: "#f4f1de" }}>State</TableCell>
              <TableCell sx={{ color: "#f4f1de" }}>City</TableCell>
              <TableCell sx={{ color: "#f4f1de" }}>Calcium (Ca)</TableCell>
              <TableCell sx={{ color: "#f4f1de" }}>Magnesium (Mg)</TableCell>
              <TableCell sx={{ color: "#f4f1de" }}>Sodium (Na)</TableCell>
              <TableCell sx={{ color: "#f4f1de" }}>Chloride (Cl)</TableCell>
              <TableCell sx={{ color: "#f4f1de" }}>Sulfate (SO4)</TableCell>
              <TableCell sx={{ color: "#f4f1de" }}>Alkalinity</TableCell>
              <TableCell sx={{ color: "#f4f1de" }}>pH</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProfiles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((profile, index) => (
              <TableRow key={index} onClick={() => handleRowClick(profile)} style={{ cursor: 'pointer' }}>
                <TableCell>{profile.country}</TableCell>
                <TableCell>{profile.state}</TableCell>
                <TableCell>{profile.city}</TableCell>
                <TableCell>{profile.ca_value}</TableCell>
                <TableCell>{profile.mg_value}</TableCell>
                <TableCell>{profile.na_value}</TableCell>
                <TableCell>{profile.cl_value}</TableCell>
                <TableCell>{profile.so4_value}</TableCell>
                <TableCell>{profile.alkalinity_value}</TableCell>
                <TableCell>{profile.ph_value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredProfiles.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Detail Dialog */}



      {selectedProfile && (
        <Dialog open={detailDialogOpen} onClose={handleDetailDialogClose} fullWidth maxWidth="sm">
          <DialogTitle>Water Profile Details</DialogTitle>
          <DialogContent>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell><strong>Calcium (Ca):</strong></TableCell>
                  <TableCell>{selectedProfile.ca_value} mg/L</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Magnesium (Mg):</strong></TableCell>
                  <TableCell>{selectedProfile.mg_value} mg/L</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Sodium (Na):</strong></TableCell>
                  <TableCell>{selectedProfile.na_value} mg/L</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Chloride (Cl):</strong></TableCell>
                  <TableCell>{selectedProfile.cl_value} mg/L</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Sulfate (SO4):</strong></TableCell>
                  <TableCell>{selectedProfile.so4_value} mg/L</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Alkalinity:</strong></TableCell>
                  <TableCell>{selectedProfile.alkalinity_value} mg/L</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>pH:</strong></TableCell>
                  <TableCell>{selectedProfile.ph_value}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Source:</strong></TableCell>
                  <TableCell>
                    {selectedProfile.link ? (
                      <a href={selectedProfile.link} target="_blank" rel="noopener noreferrer">View Source</a>
                    ) : 'No source available'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Time Stamp:</strong></TableCell>
                  <TableCell>{formatDate(selectedProfile.year, selectedProfile.month, selectedProfile.day)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography
              variant="body1"
              sx={{
                marginTop: '20px',
                paddingLeft: '16px',
                lineHeight: 1.8,
                textAlign: 'justify',
                color: '#2b2d42',
              }}
            >
              <strong>Disclaimer:</strong> This water profile is for informational purposes only and may not fully represent
              the water quality at your location. Always test your own water for accuracy and suitability for brewing.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginTop: '10px',
                paddingLeft: '16px',
                lineHeight: 1.8,
                textAlign: 'justify',
                color: '#2b2d42',
              }}
            >
              This water profile appears promising for brewing purposes. Please adjust for the specific beer style you intend to create.
            </Typography>
          </DialogContent>
          <Button onClick={handleDetailDialogClose} variant="outlined" sx={{ margin: '20px auto', display: 'block' }}>
            Close
          </Button>
        </Dialog>
      )}

      {/* Submit Report Button */}
      <Box sx={{ marginTop: '16px', textAlign: 'center' }}>
        <StyledButton
          component="a" // Use anchor tag behavior
          href="mailto:contact@connected-brewery.com?subject=New%20Water%20Report"
        >
          Submit Your Water Report
        </StyledButton>
      </Box>


      <Dialog open={submitDialogOpen} onClose={handleSubmitDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Login Please</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            You have to be logged in to submit a water report.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitDialogClose} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Context and Educational Section */}
      <Box sx={{ marginTop: '40px', textAlign: 'justify' }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            marginBottom: '16px',
            color: '#2b2d42',
            textAlign: 'center',
          }}
        >
          Understanding Water Profiles in Brewing
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', lineHeight: '1.8', paddingLeft: '16px' }}>
          Water is one of the most critical yet overlooked components in brewing. It not only constitutes the largest
          portion of the beer but also significantly impacts its flavor, mouthfeel, and clarity. A good understanding of
          your water profile allows you to fine-tune the brewing process for optimal results.
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            marginBottom: '8px',
            paddingLeft: '16px',
            color: '#2b2d42',
          }}
        >
          What is a Water Profile?
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', lineHeight: '1.8', paddingLeft: '32px' }}>
          A water profile details the chemical composition of water, highlighting the concentrations of various ions
          such as calcium, magnesium, sodium, chloride, sulfate, and alkalinity. These elements interact with brewing
          ingredients to influence mash pH, fermentation efficiency, and the overall taste of the beer. Each brewing
          style benefits from a specific water profile that complements its unique flavor profile.
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            marginBottom: '8px',
            paddingLeft: '16px',
            color: '#2b2d42',
          }}
        >
          Why Adjust Your Water?
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', lineHeight: '1.8', paddingLeft: '32px' }}>
          Water adjustment is particularly important for brewers aiming to replicate a specific beer style or enhance
          certain flavor characteristics. For example, a high sulfate-to-chloride ratio accentuates hop bitterness,
          ideal for IPAs, while a balanced profile promotes maltiness, suitable for stouts and porters. By tweaking
          your water profile, you can unlock the full potential of your recipe.
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            marginBottom: '8px',
            paddingLeft: '16px',
            color: '#2b2d42',
          }}
        >
          How to Read a Water Report
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', lineHeight: '1.8', paddingLeft: '32px' }}>
          A typical water report includes details about the mineral content and pH of the water. Key parameters include:
        </Typography>
        <Typography variant="body2" sx={{ paddingLeft: '48px', marginBottom: '16px' }}>
          - <strong>Calcium (Ca):</strong> Enhances mash enzyme activity and contributes to beer clarity and stability.
        </Typography>
        <Typography variant="body2" sx={{ paddingLeft: '48px', marginBottom: '16px' }}>
          - <strong>Magnesium (Mg):</strong> Supports yeast metabolism but should be kept at moderate levels.
        </Typography>
        <Typography variant="body2" sx={{ paddingLeft: '48px', marginBottom: '16px' }}>
          - <strong>Sodium (Na):</strong> Adds a soft, round mouthfeel to the beer but should not exceed 100 mg/L.
        </Typography>
        <Typography variant="body2" sx={{ paddingLeft: '48px', marginBottom: '16px' }}>
          - <strong>Chloride (Cl):</strong> Accentuates malt sweetness and body.
        </Typography>
        <Typography variant="body2" sx={{ paddingLeft: '48px', marginBottom: '16px' }}>
          - <strong>Sulfate (SO4):</strong> Enhances hop bitterness and dry finish.
        </Typography>
        <Typography variant="body2" sx={{ paddingLeft: '48px', marginBottom: '16px' }}>
          - <strong>Alkalinity:</strong> Impacts mash pH and overall beer balance.
        </Typography>
        <Typography variant="body2" sx={{ paddingLeft: '48px', marginBottom: '16px' }}>
          - <strong>pH:</strong> Optimal mash pH ranges between 5.2 and 5.6 for enzymatic efficiency.
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            marginBottom: '8px',
            paddingLeft: '16px',
            color: '#2b2d42',
          }}
        >
          Safety First: Handling Water and Chemicals
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', lineHeight: '1.8', paddingLeft: '32px' }}>
          Working with hot liquids and chemical additives requires caution. Always use heat-resistant glassware to
          prevent cracking and wear protective gear to avoid burns or accidental exposure to chemicals. Ensure your
          brewing workspace is well-ventilated and free from contaminants.
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            marginBottom: '8px',
            paddingLeft: '16px',
            color: '#2b2d42',
          }}
        >
          The Role of Software and Tools
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', lineHeight: '1.8', paddingLeft: '32px' }}>
          Modern brewing software and calculators simplify the process of water adjustment. By inputting your water
          profile and desired beer style, these tools suggest the necessary adjustments to achieve the optimal balance.
          Always cross-reference suggestions with your water report for accuracy.
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            marginTop: '32px',
            marginBottom: '16px',
            color: '#2b2d42',
            textAlign: 'center',
          }}
        >
          Share Your Feedback
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px', lineHeight: '1.8', paddingLeft: '16px' }}>
          We’re passionate about empowering brewers with the tools and knowledge to perfect their craft. Whether you’re
          a novice or a seasoned brewer, your feedback helps us improve and innovate. Share your thoughts or submit your
          water reports to contribute to the community.
        </Typography>
      </Box>

    </Box>
  );
};

export default WaterProfiles;
