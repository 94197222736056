import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Dialog, Typography, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom'; // Import Link for navigation
import logo from '../assets/images/hasabrewery-logo.png';

const Header = () => {
  const [opacity, setOpacity] = useState(1);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  // Handle scroll for header transparency
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const newOpacity = Math.max(1 - scrollTop / 200, 0);
    setOpacity(newOpacity);
  };

  // Scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Open the informational popup
  const showInfoPopup = () => {
    setPopupOpen(true);
  };

  // Close the informational popup
  const closePopup = () => {
    setPopupOpen(false);
  };

  // Toggle the mobile drawer
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <>
      <Box
        className="header"
        sx={{
          backgroundColor: `rgba(240, 244, 248, ${opacity})`,
          boxShadow: `0 2px 10px rgba(0, 0, 0, ${opacity * 0.1})`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 20px',
        }}
      >
        {/* Logo on the Left */}
        <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
          <Link to="/">
            <img
              src={logo}
              alt="HasABrewery Logo"
              className="header-logo"
              style={{
                height: 'auto',
                width: '150px',
                maxWidth: '100%',
                objectFit: 'contain',
              }}
            />
          </Link>
        </Box>


        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: '20px' }}>
        {/* <Button color="inherit" onClick={showInfoPopup}>
            Shop
          </Button>
          <Button  color="inherit" onClick={showInfoPopup}>
            Community
          </Button> */}
          <Link to="/tools" style={{ textDecoration: 'none' }}>
            <Button variant="text" color="inherit" sx={{ color: '#333' }}>
              Tools
            </Button>
          </Link>
          {/* <Link to="/brew-education" style={{ textDecoration: 'none' }}>
            <Button variant="text" color="inherit" sx={{ color: '#333' }}>
              Brew Education
            </Button> */}
          {/* </Link> */}
          <Link to="/get-started" style={{ textDecoration: 'none' }}>
            <Button variant="text" color="inherit" sx={{ color: '#333' }}>
              Get Started
            </Button>
            </Link>
          {/* <Link to="/support-project" style={{ textDecoration: 'none' }}>
            <Button variant="text" color="inherit" sx={{ color: '#333' }}>
              Support Project
            </Button>
          </Link> */}
          <Link to="/login" style={{ textDecoration: 'none' }}>
            <Button variant="outlined" color="inherit" sx={{ color: '#333' }}>
              Login
            </Button>
          </Link>
        </Box>

        {/* Mobile Burger Menu */}
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton color="inherit" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <Box
          sx={{
            width: 250,
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <IconButton onClick={toggleDrawer(false)} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon />
          </IconButton>
          {/* <Button color="inherit" onClick={showInfoPopup}>
            Shop
          </Button>
          <Button color="inherit" onClick={showInfoPopup}>
            Community
          </Button> */}
          <Link to="/tools" style={{ textDecoration: 'none' }}>
            <Button variant="text" color="inherit" onClick={toggleDrawer(false)} sx={{ color: '#333' }} >
              Tools
            </Button>
          </Link>
          <Link to="/brew-education" style={{ textDecoration: 'none' }}>
            <Button variant="text" color="inherit" onClick={toggleDrawer(false)} sx={{ color: '#333' }} >
              Brew Education
            </Button>
          </Link>
          <Link to="/get-started" style={{ textDecoration: 'none' }}>
            {/* <Button variant="text" color="inherit" onClick={toggleDrawer(false)} sx={{ color: '#333' }} >
              Get Started
            </Button> */}
          </Link>
          {/* <Link to="/support-project" style={{ textDecoration: 'none' }}>
            <Button variant="text" color="inherit" sx={{ color: '#333' }}>
              Support Project
            </Button>
            </Link> */}
          <Link to="/login" style={{ textDecoration: 'none' }}>
          <Button variant="outlined" color="inherit" sx={{ color: '#333' }}>
          Login
            </Button>
          </Link>
        </Box>
      </Drawer>

      {/* Informational Popup Dialog */}
      <Dialog
        open={isPopupOpen}
        onClose={closePopup}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        PaperProps={{
          sx: {
            backgroundColor: '#f0f4f8',
            padding: '20px',
            textAlign: 'center',
            borderRadius: '12px',
            width: '100%',
            maxWidth: '400px',
          },
        }}
      >
        <Box>
          <Typography variant="h3" sx={{ color: '#2b2d42', marginBottom: '16px' }}>
            Coming Soon!
          </Typography>
          <p style={{ color: '#2b2d42', marginBottom: '20px' }}>
            This feature is currently under development. Stay tuned for updates!
          </p>
          <Button
            variant="contained"
            onClick={closePopup}
            sx={{
              backgroundColor: '#2b2d42',
              color: '#f0f4f8',
              borderRadius: '8px',
              padding: '10px 20px',
              fontWeight: 'bold',
            }}
          >
            Close
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default Header;
