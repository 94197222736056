import React from 'react';
import { Box, Stack, Button } from '@mui/material';
import { Link } from 'react-scroll';
import { styled } from '@mui/system';
import landingImage from '../assets/images/landing-image.png';
import { Link as RouterLink } from 'react-router-dom';
// import landingVideo from '../assets/videos/demo_video.mp4';

const StyledButton = styled(Button)({
  padding: '10px 20px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  backgroundColor: '#2b2d42',
  color: '#f4f1de',
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
  transition: 'background-color 0.3s ease-in-out, transform 0.2s',
  '&:hover': {
    backgroundColor: '#FABC18',
    color: '#2b2d42',
    transform: 'scale(1.15)',
  },
});

const Landing = () => {
  return (
    <Box
      className="section"
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        padding: { xs: '20px', md: '40px' },
        minHeight: '100vh',
        gap: { xs: '20px', md: '40px' },
      }}
    >
 
      <Stack
        className="landing-left"
        sx={{
          flex: 1,
          paddingLeft: { xs: '0', md: '24px' },
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <div className="vertical-space-24"></div>
        <div className="vertical-space-24"></div>
        <div className="vertical-space-24"></div>
        <h1>Your Own Brewery!</h1>
        <h4>We are building a free & open platform for homebrewers to create their own brewery.</h4>
        <p>
          The 'Secret of Brewing' is not so secret for those in the know. <br />
          The secret is cleanliness and consistency. HasABrewery provides the best education and guidance
          to help with your cleanliness, but even more, it has the tools & platform to achieve consistency –
          which results in better beer!
        </p>
        <p>Let's take out the guessing game and get into the brew science!</p>
        <div className="vertical-space-24"></div>

        <StyledButton className="styled-button" component={RouterLink} to="/get-started">
          Get Started Now!
        </StyledButton>
      </Stack>
      <Stack
        className="landing-right"
        sx={{
          flex: 1,
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src={landingImage}
          alt="Landing Image"
          sx={{
            width: { xs: '100%', md: '450px' },
            maxWidth: '100%',
            borderRadius: '12px',
            objectFit: 'cover',
          }}
        />
        <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
          <h4 style={{ color: '#FABC18' }}>Monitor.</h4>
          <h4 style={{ color: '#4c9a2a' }}>Control.</h4>
          <h4 style={{ color: '#945a31' }}>Automate.</h4>
        </Box>
      </Stack>
    </Box>
  );
};

export default Landing;
