import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, List, ListItem, ListItemText, Collapse, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import '../global.css';

const EducationSidebar = () => {
    const [openSection, setOpenSection] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const sidebarRef = useRef(null);

    const handleToggleSection = (section) => {
        setOpenSection(openSection === section ? null : section);
    };

    const handleToggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setSidebarOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <button className="sidebar-toggle" onClick={handleToggleSidebar}>
                {sidebarOpen ? '<' : '>'}
            </button>
            <div className={`tools-sidebar ${sidebarOpen ? 'open' : ''}`} ref={sidebarRef}>
                <List>
                    <ListItem button onClick={() => handleToggleSection('system')}>
                        <ListItemText
                            primary={
                                <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    System Overview
                                </span>
                            }
                        />
                        {openSection === 'system' ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSection === 'system'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <hr></hr>
                            {/* Add ListItems here */}

                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Extraxt Kits" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Brew In A Bag" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="All-in-1 System" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="3 Vessel - HERMS" className="sidebar-link" />
                            </ListItem>
                            <hr></hr>
                            <hr></hr>
                        </List>
                    </Collapse>
                    <ListItem button onClick={() => handleToggleSection('steps')}>
                        <ListItemText
                            primary={
                                <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    Step by Step
                                </span>
                            }
                        />
                        {openSection === 'steps' ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSection === 'steps'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <hr></hr>
                            {/* Add ListItems here */}

                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Brewing Process" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Cleaning" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Water Preperation" className="sidebar-link" />
                            </ListItem>

                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Milling the Grains" className="sidebar-link" />
                            </ListItem>
                            <hr></hr>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Mashing" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Boiling & Hopping" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Cooling" className="sidebar-link" />
                            </ListItem>
                            <hr></hr>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Fermentation" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Dry Hopping" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Conditioning" className="sidebar-link" />
                            </ListItem>
                            <hr></hr>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Drinking" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Testing & Learning" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Record Keeping" className="sidebar-link" />
                            </ListItem>

                            <hr></hr>
                            <hr></hr>
                        </List>
                    </Collapse>
                    <ListItem button onClick={() => handleToggleSection('equipment')}>
                        <ListItemText
                            primary={
                                <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    Equipment
                                </span>
                            }
                        />
                        {openSection === 'equipment' ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSection === 'equipment'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <hr></hr>
                            {/* Add ListItems here */}

                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Summary" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Coming Soon" className="sidebar-link" />
                            </ListItem>

                            <hr></hr>
                            <hr></hr>
                        </List>
                    </Collapse>
                    <ListItem button onClick={() => handleToggleSection('recipes')}>
                        <ListItemText
                            primary={
                                <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    Recipes
                                </span>
                            }
                        />
                        {openSection === 'recipes' ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSection === 'recipes'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <hr></hr>
                            {/* Add ListItems here */}

                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Beer Color" className="sidebar-link" />
                            </ListItem>
                            <hr></hr>
                            <hr></hr>
                        </List>
                    </Collapse>
                    <ListItem button onClick={() => handleToggleSection('glossary')}>
                        <ListItemText
                            primary={
                                <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    Glossary
                                </span>
                            }
                        />
                        {openSection === 'glossary' ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSection === 'glossary'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <hr></hr>
                            {/* Add ListItems here */}

                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Abbreviation" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Definitions" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Ressources" className="sidebar-link" />
                            </ListItem>
                            <hr></hr>
                            <hr></hr>
                        </List>
                    </Collapse>
                    <ListItem button onClick={() => handleToggleSection('faq')}>
                        <ListItemText
                            primary={
                                <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    FAQ
                                </span>
                            }
                        />
                        {openSection === 'faq' ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSection === 'faq'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <hr></hr>
                            {/* Add ListItems here */}

                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Coming Soon" className="sidebar-link" />
                            </ListItem>
                            <hr></hr>
                            <hr></hr>
                        </List>
                    </Collapse>
                    <ListItem button onClick={() => handleToggleSection('support')}>
                        <ListItemText
                            primary={
                                <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    Support
                                </span>
                            }
                        />
                        {openSection === 'support' ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSection === 'support'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <hr></hr>
                            {/* Add ListItems here */}

                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Coming Soon" className="sidebar-link" />
                            </ListItem>
                            <hr></hr>
                            <hr></hr>
                        </List>
                    </Collapse>
                    <ListItem button onClick={() => handleToggleSection('about')}>
                        <ListItemText
                            primary={
                                <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    About Us
                                </span>
                            }
                        />
                        {openSection === 'about' ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSection === 'about'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <hr></hr>
                            {/* Add ListItems here */}

                            <ListItem button component={NavLink} to="/brew-education" onClick={handleToggleSidebar}>
                                <ListItemText primary="Our Professors" className="sidebar-link" />
                            </ListItem>
                            <hr></hr>
                            <hr></hr>
                        </List>
                    </Collapse>
                </List>
            </div>
        </>
    );
};

export default EducationSidebar;
