import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Button,
  Radio,
  FormControlLabel,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/system';
import axios from 'axios';

const LookUpGrain = ({ open, onClose, rowIndex, onSelect }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(500));

  const [grains, setGrains] = useState([]);
  const [totalGrains, setTotalGrains] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedGrain, setSelectedGrain] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false); // Add loading state
  const rowsPerPage = 10;

  const fetchGrains = async (query = '', currentPage = 1) => {
    try {
      setLoading(true); // Set loading state
      const response = await axios.get(
        `https://avgzss5tta.execute-api.us-east-1.amazonaws.com/search?q=${query}&page=${currentPage}`
      );
      const { totalValuesFound, grains } = response.data;

      setGrains(grains || []); // Fallback to an empty array if no grains are found
      setTotalGrains(totalValuesFound || 0); // Fallback to 0 if no total is returned
    } catch (error) {
      console.error('Error fetching grains:', error);
      setGrains([]);
      setTotalGrains(0);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  useEffect(() => {
    if (open) {
      fetchGrains(searchQuery, page); // Fetch data when modal opens or updates
    }
  }, [open, page, searchQuery]);

  const handleSearch = () => {
    setPage(1); // Reset to page 1 on new search
    fetchGrains(searchQuery, 1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSelect = () => {
    if (selectedGrain) {
      const { ebc } = selectedGrain;
      onSelect(rowIndex, ebc, 'EBC'); // Example using EBC as the color unit
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="lookup-grain-modal"
      aria-describedby="lookup-grain-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { 
            xs: 'calc(100% - 40px)', 
            sm: '80vw' 
          },
          maxWidth: { 
            sm: '800px' 
          },
          
          height: { 
            xs: 'calc(100% - 60px)', 
            sm: '90vh' 
          },
          maxHeight: { 
            sm: '1000px' 
          },
          
          backgroundColor: 'white',
          borderRadius: '10px',
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            color: '#2b2d42',
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Modal Content */}
        <Box sx={{ padding: '20px', flex: 1, overflow: 'auto' }}>
          {/* Headline */}
          <Typography variant="h5" sx={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold' }}>
            Look Up Grain Database
          </Typography>



          {/* Info Text */}
          <Typography variant="body2" sx={{ marginBottom: '10px', textAlign: 'center' }}>
            <b>Important:</b> If you already have a value in you cell, this will override with the EBC color.<br />
            More informtaion below.
          </Typography>

          {/* Search Bar */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '10px',
            }}
          >
            <SearchIcon sx={{ color: '#2b2d42' }} />
            <TextField
              placeholder="Search malt..."
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' } }}
            />
            <Button
              variant="contained"
              onClick={handleSearch}
              sx={{
                backgroundColor: '#2b2d42',
                color: 'white',
                '&:hover': { backgroundColor: '#FABC18' },
              }}
            >
              Search
            </Button>
          </Box>

          {/* Number of Results */}
          <Typography variant="body2" sx={{ marginBottom: '10px', color: '#888' }}>
            {loading ? 'Loading...' : `${totalGrains} Grains Found`}
          </Typography>

          {/* Results Table */}
          {!loading && (
            <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#2b2d42' }}>
                    <TableCell sx={{ color: '#fcfcf8', backgroundColor: '#2b2d42', fontWeight: 'bold' }}>Select</TableCell>
                    <TableCell sx={{ color: '#fcfcf8', backgroundColor: '#2b2d42', fontWeight: 'bold' }}>Malt Name</TableCell>
                    <TableCell sx={{ color: '#fcfcf8', backgroundColor: '#2b2d42', fontWeight: 'bold' }}>SRM</TableCell>
                    <TableCell sx={{ color: '#fcfcf8', backgroundColor: '#2b2d42', fontWeight: 'bold' }}>EBC</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {grains.length > 0 ? (
                    grains.map((grain) => (
                      <TableRow key={grain.id}>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={selectedGrain?.id === grain.id}
                                onChange={() => setSelectedGrain(grain)}
                                sx={{
                                  color: '#2b2d42',
                                  '&.Mui-checked': {
                                    color: '#FABC18',
                                  },
                                }}
                              />
                            }
                            label=""
                          />
                        </TableCell>
                        <TableCell>{grain.name}</TableCell>
                        <TableCell>{grain.srm}</TableCell>
                        <TableCell>{grain.ebc}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No grains found. Please try a different search query.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {/* Pagination */}
          <Pagination
            count={Math.ceil(totalGrains / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
            sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
          />
          {/* Info Text */}
          <Typography variant="body2" sx={{ marginBottom: '10px', textAlign: 'center' }}>
            <b>Notes:</b> The calculator supports various units and color scales.  When selecting a grain and sending
            it to the calculator, by default the EBC value and the EBC unit are shown. This has <b>no effect</b> on the
            calculations and results. <br />
            No need to be worried. All values are converted to the selected unit and scale.
            <br />
            If you manually change the units in the calculator the value in the cell will not be converted! Leading to a potentially
            wrong result.
            <br />

          </Typography>
        </Box>

        {/* Button Row */}
        <Box
          sx={{
            backgroundColor: '#f0f0f0',
            padding: '8px 12px',
            borderTop: '1px solid #ccc',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            startIcon={<CancelIcon />}
            sx={{
              borderColor: '#2b2d42',
              color: '#2b2d42',
              '&:hover': { borderColor: '#FABC18', color: '#FABC18' },
            }}
          >
            {isSmallScreen ? 'Cancel' : 'Cancel & Return'}
          </Button>
          <Button
            onClick={handleSelect}
            variant="contained"
            startIcon={<DownloadIcon />}
            sx={{
              backgroundColor: '#2b2d42',
              color: 'white',
              '&:hover': { backgroundColor: '#FABC18' },
            }}
            disabled={!selectedGrain}
          >
            {isSmallScreen ? 'Use Grain' : 'Send Color to Calculator'}
          </Button>
        </Box>

      </Box>

    </Modal>
  );
};


export default LookUpGrain;
