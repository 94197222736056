import '../global.css';

export const logImperialSwitch = () => {
  console.log('Ah, someone is choosing freedom over reasonability');
  triggerImperialAnimation();
};

export const logMetricSwitch = () => {
  console.log('Look at you. Rather follow someone else than be free');
  triggerMetricAnimation();
};

const ANIMATION_DURATION = 2; // Duration in seconds

const triggerImperialAnimation = () => {
  const container = document.getElementById('eagle-container');
  if (!container) return;

  // Clear previous animations
  container.innerHTML = '';

  // Generate random start and end positions
  const { start, end } = getRandomAnimationPositions();

  // Create the eagle element
  const eagle = document.createElement('img');
  eagle.src = '/assets/icons/eagle.png';
  eagle.style.position = 'fixed';
  eagle.style[start.side] = start.value; // Random start side
  eagle.style[start.crossAxisSide] = start.crossAxisValue; // Random cross-axis position
  eagle.style.width = '200px'; // Adjust size
  eagle.style.height = 'auto'; // Maintain aspect ratio
  eagle.style.transition = `${start.side} ${ANIMATION_DURATION}s linear, ${start.crossAxisSide} ${ANIMATION_DURATION}s linear`; // Smooth animation

  container.appendChild(eagle);

  // Trigger the animation
  setTimeout(() => {
    eagle.style[start.side] = end.value; // Move to the opposite side
    eagle.style[start.crossAxisSide] = end.crossAxisValue; // Adjust cross-axis to match end
  }, 0);

  // Play eagle scream sound after 250ms
  setTimeout(() => {
    const audio = new Audio('/assets/sounds/eagle-scream.mp3');
    audio.play();
  }, 250);

  // Remove eagle after animation finishes
  setTimeout(() => {
    container.removeChild(eagle);
  }, ANIMATION_DURATION * 1000);
};

const triggerMetricAnimation = () => {
  const container = document.getElementById('eagle-container');
  if (!container) {
    console.error('eagle-container not found');
    return;
  }

  // Clear previous animations
  container.innerHTML = '';

  // Generate random start and end positions
  const { start, end } = getRandomAnimationPositions();

  // Create the GIF element
  const gif = document.createElement('img');
  gif.src = '/assets/icons/metric-selected-scheiwie.gif'; // Path to the GIF
  gif.style.position = 'fixed';
  gif.style[start.side] = start.value; // Random start side
  gif.style[start.crossAxisSide] = start.crossAxisValue; // Random cross-axis position
  gif.style.width = '300px'; // Adjust size
  gif.style.height = 'auto'; // Maintain aspect ratio
  gif.style.transition = `${start.side} ${ANIMATION_DURATION}s linear, ${start.crossAxisSide} ${ANIMATION_DURATION}s linear`; // Smooth animation

  container.appendChild(gif);

  // Play the sound
  const audio = new Audio('/assets/sounds/metric-selected-scheiwie.mp3');
  audio.volume = 0.2; // Set volume to 0.5
  audio.play().catch((error) => {
    console.error('Audio playback failed:', error);
  });

  // Trigger the animation
  setTimeout(() => {
    gif.style[start.side] = end.value; // Move to the opposite side
    gif.style[start.crossAxisSide] = end.crossAxisValue; // Adjust cross-axis to match end
  }, 0);

  // Remove the GIF after animation finishes
  setTimeout(() => {
    container.removeChild(gif);
  }, ANIMATION_DURATION * 1000);
};

// Helper function to get random start and end positions
const getRandomAnimationPositions = () => {
  const sides = ['top', 'bottom', 'left', 'right'];
  const startSide = sides[Math.floor(Math.random() * sides.length)];
  const isHorizontal = startSide === 'left' || startSide === 'right';

  const startCrossAxisValue = `${Math.random() * 100}vh`; // Random % along cross-axis
  const startValue = startSide === 'top' || startSide === 'bottom' ? '-200px' : '-200px';

  const endCrossAxisValue =
    isHorizontal ? `${100 - parseFloat(startCrossAxisValue)}vh` : `${100 - parseFloat(startCrossAxisValue)}vw`;
  const endValue = startSide === 'top' || startSide === 'bottom' ? '100vh' : '100vw';

  const crossAxisSide = isHorizontal ? 'top' : 'left';

  return {
    start: {
      side: startSide,
      value: startValue,
      crossAxisSide,
      crossAxisValue: startCrossAxisValue,
    },
    end: {
      side: getOppositeSide(startSide),
      value: endValue,
      crossAxisSide,
      crossAxisValue: endCrossAxisValue,
    },
  };
};

// Helper function to get the opposite side
const getOppositeSide = (side) => {
  const opposites = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
  };
  return opposites[side];
};
