import React, { useState } from 'react';
import { Box, Typography, Select, MenuItem } from '@mui/material';
import { Helmet } from 'react-helmet';

const WaterProfileCalc = () => {
    const [unit, setUnit] = useState('Metric');

    const switchToMetric = () => {
        setUnit('Metric');
    };

    const switchToImperial = () => {
        setUnit('Imperial');
    };

    return (
        <div>
            <Helmet>
                <title>Water Profiles - Has A Brewery</title>
                <meta name="description" content="Explore water profiles shared by homebrewers worldwide. Filter by location and access detailed brewing water information." />
                <meta property="og:title" content="Water Profiles - Has A Brewery" />
                <meta property="og:description" content="Discover water profiles for brewing from various regions. Access detailed information and share your own report to help the community." />
                <meta property="og:image" content="/images/water-profiles-thumbnail.jpg" />
                <meta property="og:url" content="https://hasabrewery.com/tools/water-profiles" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Water Profiles - Has A Brewery" />
                <meta name="twitter:description" content="Explore water profiles shared by homebrewers worldwide. Filter by location and access detailed brewing water information." />
                <meta name="twitter:image" content="/images/water-profiles-thumbnail.jpg" />
            </Helmet>

            <Box sx={{ padding: '20px', minHeight: '100vh', maxWidth: '1200px', display: 'flex', flexDirection: 'column', gap: '20px', backgroundColor: '#FAF9F1' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #ccc',
                        paddingBottom: '10px',
                        flexDirection: { xs: 'column-reverse', sm: 'row' },
                        gap: { xs: '10px', sm: '0' },
                        '@media (max-width: 550px)': {
                            flexDirection: 'column-reverse',
                            alignItems: 'flex-end',
                        },
                        '@media (max-width: 600px) and (min-width: 551px)': {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <Typography variant="h4" component="h1" gutterBottom>
                        Water Profiles
                    </Typography>
                    <Select
                        value={unit}
                        onChange={(e) => (e.target.value === 'Metric' ? switchToMetric() : switchToImperial())}
                        variant="outlined"
                        size="small"
                        sx={{ width: '150px' }}
                    >
                        <MenuItem value="Metric">Metric</MenuItem>
                        <MenuItem value="Imperial">Imperial</MenuItem>
                    </Select>
                </Box>
                {/* Add your content here */}
            </Box>
        </div>
    );
};

export default WaterProfileCalc;
