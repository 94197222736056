import React from 'react';
import ToolsSidebar from '../components/ToolsSidebar';
import LoginCallToAction from '../components/LoginCallToAction';
import ToolsDisclaimer from '../components/ToolsDisclaimer';
import { Outlet, useLocation } from 'react-router-dom';
import '../global.css';

const Tools = () => {
  const location = useLocation();

  // Check if the current path is exactly `/tools`
  const isLandingPage = location.pathname === '/tools';

  return (
    <div className="tools-layout" style={{ backgroundColor: '#FAF9F1', padding: '20px' }}>
      {/* Sidebar */}
      <ToolsSidebar />

      
        <div className="tools-content" style={{ backgroundColor: '#FAF9F1' }}>
          {isLandingPage ? (
            <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
          <h1 style={{ textAlign: 'center', fontSize: '2.5rem', color: '#2b2d42' }}>Welcome to Our Tools</h1>
          <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
            At <strong>Has A Brewery</strong>, we’re passionate about brewing and technology. As ambitious homebrewers
            and tech enthusiasts, we created this platform to combine our two great loves: crafting beer and developing 
            smart, user-friendly tools to make the brewing process easier, more precise, and much more fun.
          </p>
          <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
            Whether you’re a seasoned brewer or just starting your first batch, our tools are designed to empower you
            every step of the way. From calculating alcohol by volume (ABV) to adjusting hydrometer readings for
            temperature and beyond, we aim to simplify the technical side of brewing, so you can focus on perfecting
            your craft.
          </p>
          <h2 style={{ marginTop: '20px', fontSize: '2rem', color: '#2b2d42' }}>What We Offer</h2>
          <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
            Our suite of brewing tools includes calculators and converters tailored to solve real-world problems
            encountered by homebrewers. Here are some highlights of what we currently offer:
          </p>
          <ul style={{ marginLeft: '20px', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
            <li>
              <strong>ABV Calculator:</strong> Easily determine the alcohol content of your brew by entering
              your original gravity (OG) and final gravity (FG) values. Choose between the standard and alternate
              formulas for accurate results.
            </li>
            <li>
              <strong>Hydrometer Temperature Correction:</strong> Adjust your gravity readings based on the
              temperature difference between your liquid and your hydrometer's calibration.
            </li>
            <li>
              <strong>Specific Gravity to Plato Converter:</strong> Effortlessly switch between these two
              scales to better understand your brew's sugar content.
            </li>
            <li>
              <strong>Custom Tools:</strong> We’re continually working on new features, including water chemistry
              adjustment calculators, bitterness estimators (IBUs), and much more.
            </li>
          </ul>
          <h2 style={{ marginTop: '20px', fontSize: '2rem', color: '#2b2d42' }}>Our Vision</h2>
          <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
            At the heart of our project is the belief that brewing should be accessible to everyone. That’s why
            all our tools are free to use and always will be. We understand the challenges of brewing at home and
            want to provide a platform that educates, inspires, and supports you in achieving your brewing goals.
          </p>
          <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
            Our mission is to bring together the brewing community by sharing knowledge and tools that encourage
            experimentation, creativity, and collaboration. Whether you’re creating a traditional pilsner or a
            cutting-edge sour ale, we want to be your partner in brewing success.
          </p>
          <h2 style={{ marginTop: '20px', fontSize: '2rem', color: '#2b2d42' }}>We Want to Hear From You!</h2>
          <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
            Have feedback, suggestions, or ideas for new tools? We’d love to hear from you! Our platform thrives
            on collaboration, and we’re always looking to improve and expand what we offer. Whether it’s a specific
            calculator you need or a new feature idea, let us know, and we’ll do our best to bring it to life.
          </p>
          <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
            Connect with us on social media, share your brewing stories, and help us make the brewing world a little
            smaller and a lot more connected.
          </p>
            </div>
          ) : (
            <Outlet />
          )}
          <div style={{ margin: '20px 0' }}></div>
          <hr />
          {/* Login Call to Action */}
        <div className="login-call-to-action">
          <LoginCallToAction />
        </div>
        <hr />
        {/* Tools Disclaimer */}
        <div className="tools-disclaimer">
          <ToolsDisclaimer />
        </div>
      </div>
    </div>
  );
};

export default Tools;
