import React from 'react';

const EducationDisclaimer = () => {
  return (
    <div>
      <p style={{ fontStyle: 'italic', fontSize: '0.9rem', color: '#6c757d', lineHeight: '1.5' }}>
        <strong>Important Education Notice:</strong> The educational content and resources provided by Has A Brewery are part of an open,
        hobby-driven project. This information is contributed by multiple members of the brewing community and reflects the
        current state of knowledge. As brewing science evolves, this content may change, and errors or inaccuracies may
        occasionally occur.
        <br />
        If you discover any issues or inaccuracies, or if you have questions about the information provided, please don’t
        hesitate to reach out to us. We value your feedback and are always eager to improve and expand this resource for
        the homebrewing community.
      </p>


    </div>
  );
};

export default EducationDisclaimer;
