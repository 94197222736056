import React, { useState } from 'react';
import { Box, TextField, Button, Stack } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)({
  padding: '10px 20px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  backgroundColor: '#2b2d42',
  color: '#f4f1de',
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
  transition: 'background-color 0.3s ease-in-out, transform 0.2s',
  '&:hover': {
    backgroundColor: '#FABC18',
    color: '#2b2d42',
    transform: 'scale(1.0)',
  },
});

const Signup = () => {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  // Lambda API Gateway URL
  const API_URL = 'https://05e52f7zgc.execute-api.us-east-1.amazonaws.com/prod/signup';

  // Handle email input change
  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setIsValidEmail(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!isValidEmail || email.trim() === '') {
      setMessage('Please enter a valid email address.');
      return;
    }
  
    setLoading(true);
    setMessage('');
  
    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify({ email }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setMessage('You have been successfully subscribed!');
        setEmail('');
      } else {
        setMessage(`Subscription failed: ${data.message || 'Unknown error occurred.'}`);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again later.');
    }
  
    setLoading(false);
  };
  
  


  return (
    <Box id="signup-section" className="signup-container">
      <Stack className="signup-left">
        <h2>Waiting List & Newsletter!</h2>
        <h4>Sign up for updates and the waiting list!</h4>
        <Box className="vertical-space" />
        <p>
          We will keep you posted once we have updates to share and let you know when the platform is ready for signing up.
          All subscribers will be added to the waiting list and get early access to the platform and can test beta releases to provide feedback.
        </p>
        <Box className="vertical-space" />
      </Stack>

      {/* Right Side: Signup Form */}
      <Stack
        className="signup-right"
        sx={{
          paddingLeft: { xs: '16px', md: '80px' },
          paddingRight: { xs: '16px', md: '0' },
          alignItems: 'center',
        }}
      >
        <h4>Join our Newsletter</h4>
        <Box className="vertical-space" />

        <TextField
          label="Email Address"
          variant="outlined"
          className="signup-input"
          sx={{
            width: { xs: '350px', md: '450px' },
          }}
          value={email}
          onChange={handleEmailChange}
          error={!isValidEmail}
          helperText={!isValidEmail && 'Please enter a valid email address'}
        />
        <Box className="vertical-space" />

        <StyledButton
          variant="contained"
          className="subscribe-button"
          sx={{
            width: { xs: '350px', md: '450px' },
          }}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? 'Subscribing...' : 'Subscribe'}
        </StyledButton>

        <Box className="vertical-space" />
        {message && <p style={{ color: isValidEmail ? 'green' : 'red' }}>{message}</p>}
      </Stack>
    </Box>
  );
};

export default Signup;
