import React from 'react';

const ToolsDisclaimer = () => {
  return (
    <div>
      <p style={{ fontStyle: 'italic', fontSize: '0.9rem', color: '#6c757d' }}>
        <b>Important Notice:</b> The tools provided by Has A Brewery, including the calculators, are designed for
        informational and hobbyist use only. While we strive to ensure accuracy, these tools should not replace
        professional-grade brewing equipment or methods. Always verify your calculations independently when brewing for
        commercial or competition purposes.
      </p>
    </div>
  );
};

export default ToolsDisclaimer;
