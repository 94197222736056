import React, { useState } from 'react';
import {
    Box,
    Typography,
    Select,
    MenuItem,
    TextField,
    Button,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { useUnit } from '../../context/UnitContext';
import { Helmet } from 'react-helmet';
import { styled } from '@mui/system'; // Add this import

const StyledButton = styled(Button)({
    padding: '10px 20px',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    backgroundColor: '#2b2d42',
    color: '#f4f1de',
    borderRadius: '8px',
    textTransform: 'none',
    boxShadow: 'none',
    transition: 'background-color 0.3s ease-in-out, transform 0.2s',
    '&:hover': {
        backgroundColor: '#FABC18',
        color: '#2b2d42',
        transform: 'scale(1.15)',
    },
});

const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#fabc18',
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#fabc18',
        },
    },
});

const PlatoGravity = () => {
    const { unit, switchToImperial, switchToMetric } = useUnit();
    const [inputValue, setInputValue] = useState('');
    const [conversionType, setConversionType] = useState('sg-to-plato'); // SG to Plato or Plato to SG
    const [result, setResult] = useState('0');

    const validateValue = (value) => {
        const numValue = parseFloat(value);
        return (numValue >= -50 && numValue <= 50) || (numValue >= 700 && numValue <= 1300);
    };

    const correctInput = (value) => {
        const numValue = parseFloat(value);
        return numValue > 100 ? numValue / 1000 : numValue;
    };

    const handleConversion = () => {
        if (!inputValue) {
            alert('Please enter a valid value.');
            return;
        }
    
        const correctedValue = correctInput(inputValue);
    
        if (conversionType === 'sg-to-plato') {
            const sg = parseFloat(correctedValue); // Ensure input is parsed correctly
            if (sg < 0.7 || sg > 1.3) {
                alert('Specific Gravity (SG) must be between 0.7 and 1.3.');
                return;
            }
            const plato = ((sg - 1) * 1000) / 3.86;
            setResult(plato.toFixed(2));
        } else if (conversionType === 'plato-to-sg') {
            const plato = parseFloat(correctedValue); // Ensure input is parsed correctly
            if (plato < -50 || plato > 50) {
                alert('Plato must be between -50 and 50.');
                return;
            }
    
            const sg = 1 + plato / (258.6 - (plato / 258.2) * 227.1);
            setResult(sg.toFixed(3));
        } else {
            alert('Invalid conversion type.');
        }
    };
    

    return (
        <div>
            <Helmet>
                <title>Plato to Gravity Converter - Has A Brewery</title>
                <meta name="description" content="Effortlessly convert between specific gravity and Plato units for your brewing needs. Perfect for precision in any brew." />
                <meta property="og:title" content="Plato <> Gravity Converter - Has A Brewery" />
                <meta property="og:description" content="Use our Plato to Gravity Converter to seamlessly switch between brewing measurements. Ideal for homebrewers worldwide." />
                <meta property="og:image" content="/images/plato-gravity-converter-thumbnail.jpg" />
                <meta property="og:url" content="https://hasabrewery.com/tools/calculator/plato-gravity" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Plato <> Gravity Converter - Has A Brewery" />
                <meta name="twitter:description" content="Effortlessly convert between specific gravity and Plato units for your brewing needs. Perfect for precision in any brew." />
                <meta name="twitter:image" content="/images/plato-gravity-converter-thumbnail.jpg" />
            </Helmet>

            <Box sx={{ padding: '20px', minHeight: '100vh', maxWidth: '1200px', display: 'flex', flexDirection: 'column', gap: '20px', backgroundColor: '#FAF9F1' }}>
                {/* Header Section */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #ccc',
                        paddingBottom: '10px',
                        flexDirection: { xs: 'column-reverse', sm: 'row' }, // Adjust flex direction based on screen size
                        gap: { xs: '10px', sm: '0' }, // Add gap for small screens
                        '@media (max-width: 550px)': {
                            flexDirection: 'column-reverse', // Dropdown above headline on small screens
                            alignItems: 'flex-end', // Align dropdown to the right
                        },
                        '@media (max-width: 600px) and (min-width: 551px)': {
                            flexDirection: 'row', // Keep the dropdown and headline in a row
                            justifyContent: 'space-between', // Ensure they are spaced correctly
                        },
                    }}
                >
                    <Typography variant="h3">Plato & Gravity Converter</Typography>
                    <Select
                        value={unit}
                        onChange={(e) => (e.target.value === 'Metric' ? switchToMetric() : switchToImperial())}
                        variant="outlined"
                        size="small"
                        sx={{ width: '150px' }}
                    >
                        <MenuItem value="Metric">Metric</MenuItem>
                        <MenuItem value="Imperial">Imperial</MenuItem>
                    </Select>
                </Box>

                {/* Introduction */}
                <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: '16px' }}>
                    Effortlessly convert between Specific Gravity (SG) and Plato (°P) with this handy tool, tailored for brewers worldwide.
                    Specific Gravity, commonly used in the United States and other regions, measures the density of wort compared to water.
                    Plato, popular in Europe and professional brewing circles, represents the sugar content in terms of percentage by weight.
                    Both scales are vital for understanding your brew’s sugar content and fermentation progress, though preferences often depend
                    on regional and industry standards. Whether you’re converting for precision or personal preference, this tool ensures
                    accuracy. For deeper brewing insights, check out our
                    <a href="/tools/calculators/abv" style={{ color: '#4c9a2a', textDecoration: 'underline' }}> ABV Calculator</a> and
                    <a href="/tools/calculators/hydro-temp-correct" style={{ color: '#4c9a2a', textDecoration: 'underline' }}> Hydrometer Temperature Correction </a> tools to perfect your brewing process.
                </Typography>


                {/* Main Layout */}
                <Box sx={{ display: 'flex', gap: '20px', flex: '1', flexDirection: { xs: 'column', sm: 'row' } }}>
                    {/* Sidebar */}
                    <Box
                        sx={{
                            flex: '1 1 30%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            padding: '10px',
                            paddingTop: '45px',
                            borderRadius: '8px',
                        }}
                    >
                        {/* Conversion Type Picker */}
                        <Select
                            value={conversionType}
                            onChange={(e) => setConversionType(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ width: '100%' }}
                        >
                            <MenuItem value="sg-to-plato">Specific Gravity (SG) to Plato (°P)</MenuItem>
                            <MenuItem value="plato-to-sg">Plato (°P) to Specific Gravity (SG)</MenuItem>
                        </Select>
                    </Box>

                    {/* Main Content */}
                    <Box
                        sx={{
                            flex: '1 1 70%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                        }}
                    >
                        <Typography variant="body1"><b>Units:</b> {unit}</Typography>
                        <StyledTextField
                            label={conversionType === 'sg-to-plato' ? 'Enter Specific Gravity (SG)' : 'Enter Plato (°P)'}
                            type="number"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            fullWidth
                            variant="outlined"
                            error={inputValue && !validateValue(inputValue)}
                            helperText={inputValue && !validateValue(inputValue) && 'Value out of range'}
                        />
                        <StyledButton onClick={handleConversion}>
                            Convert
                        </StyledButton>
                    </Box>
                </Box>

                {/* Results Section */}
                <Box sx={{ marginTop: '40px' }}>
                    <Divider sx={{ marginBottom: '20px' }} />
                    <Typography variant="h5">Results</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: '#FAF9F1', fontWeight: 'bold' }}>Metric</TableCell>
                                    <TableCell sx={{ color: '#FAF9F1', fontWeight: 'bold' }}>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{conversionType === 'sg-to-plato' ? 'Plato (°P)' : 'Specific Gravity (SG)'}</TableCell>
                                    <TableCell>{conversionType === 'sg-to-plato' ? parseFloat(result).toFixed(2) : parseFloat(result).toFixed(3)}</TableCell>
                                    
                                </TableRow>
                                <TableRow>
                                    <TableCell>Potential Alcohol by Volume</TableCell>
                                    <TableCell>{conversionType === 'sg-to-plato' ? parseFloat((inputValue > 100 ? (( (inputValue/1000)-1)*131.25) : ((inputValue-1) * 131.25))).toFixed(2) + " %" : parseFloat((result-1) * 131.25).toFixed(2)  + " %" }</TableCell>
                                    
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>


                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 'bold',
                        marginTop: '32px',
                        marginBottom: '16px',
                        color: '#2b2d42',
                        textAlign: 'center',
                    }}
                >
                    About the Converter
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
                    The Gravity-Plato Converter simplifies the process of switching between Specific Gravity (SG) and Degrees Plato (°P),
                    enabling brewers to understand the sugar content of their wort or beer. It supports precise tracking of fermentation
                    and ensures consistency in brewing, catering to homebrewers and professionals alike.
                </Typography>

                {/* Specific Gravity (SG) */}
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 'bold',
                        marginTop: '32px',
                        marginBottom: '16px',
                        color: '#2b2d42',
                        textAlign: 'center',
                    }}
                >
                    What is Specific Gravity (SG)?
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
                    Specific Gravity measures the density of a liquid relative to water, which has an SG of 1.000 at 4°C (39°F).
                    Brewers use SG to quantify the sugar content in wort or beer, with Original Gravity (OG) marking the sugar level
                    before fermentation and Final Gravity (FG) indicating sugar levels after fermentation. It is measured using tools
                    like hydrometers or refractometers.
                </Typography>

                {/* Degrees Plato (°P) */}
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 'bold',
                        marginTop: '32px',
                        marginBottom: '16px',
                        color: '#2b2d42',
                        textAlign: 'center',
                    }}
                >
                    What is Degrees Plato (°P)?
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
                    Degrees Plato (°P) expresses the percentage of dissolved sugar by weight in a liquid. For instance, 12°P means 12 grams
                    of sugar per 100 grams of liquid. Commonly referred to as Brix or Balling, this scale is widely used in professional
                    brewing. Plato can be measured with the same tools as SG, including hydrometers and refractometers.
                </Typography>

                {/* Conversion Formulas */}
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 'bold',
                        marginTop: '32px',
                        marginBottom: '16px',
                        color: '#2b2d42',
                        textAlign: 'center',
                    }}
                >
                    Conversion Formulas
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
                    The relationship between SG and °P is essential for brewing calculations. Use the following formulas:
                </Typography>
                <Typography variant="body2" sx={{ paddingLeft: '32px', marginBottom: '16px' }}>
                    <strong>1. Convert SG to °P:</strong> °P = (SG - 1) × 258.6
                </Typography>
                <Typography variant="body2" sx={{ paddingLeft: '32px', marginBottom: '16px' }}>
                    <strong>2. Convert °P to SG:</strong> SG = 1 + (°P / (258.6 - ((°P / 258.2) × 227.1)))
                </Typography>

                {/* Why Use These Measurements? */}
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 'bold',
                        marginTop: '32px',
                        marginBottom: '16px',
                        color: '#2b2d42',
                        textAlign: 'center',
                    }}
                >
                    Why Use These Measurements?
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
                    SG helps brewers track fermentation progress and calculate alcohol content, while °P provides a direct measure of sugar
                    concentration. Together, these measurements allow for precise brewing control and accurate results.
                </Typography>

            </Box>
        </div>
    );
};

export default PlatoGravity;
