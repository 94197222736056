import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { GitHub, Share, LocalDrink, Storefront, Feedback } from '@mui/icons-material';
import backgroundImage from '../assets/images/support-project-background.jpg';

const SupportProject = () => {
  return (
    <Box
      sx={{
        padding: '20px',
        minHeight: '100vh',
        backgroundImage: `linear-gradient(to top, rgba(250, 249, 241, 1), rgba(250, 249, 241, 0.1)), url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* Support Us Header */}
      <Typography variant="h2" sx={{ textAlign: 'center', marginBottom: '20px', color: '#2b2d42' }}>
        Support Our Project
      </Typography>

      {/* Full Width Text */}
      <Typography
        variant="body1"
        sx={{
          textAlign: 'center',
          marginBottom: '40px',
          lineHeight: '1.6',
          maxWidth: '800px',
          margin: '0 auto',
        }}
      >
        Your support helps us continue building tools and resources for the homebrewing community. Whether it’s a small contribution,
        sharing our project, or simply using our affiliate links, every bit helps us grow and keep everything free for hobbyists worldwide.
      </Typography>

      {/* Share Project Section */}
      <ResponsiveRow>
        <Typography
          variant="body1"
          sx={{
            textAlign: 'justify',
            padding: '0 10px',
          }}
        >
          Help us spread the word! Share our project with friends, fellow brewers, and social media communities to bring more people
          to our platform. Together, we can grow the homebrewing community.
        </Typography>
        <Button variant="contained" color="primary" startIcon={<Share />}>
          Share Project
        </Button>
      </ResponsiveRow>

      {/* PayPal Section */}
      <ResponsiveRow>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#FABC18',
            color: '#2b2d42',
            '&:hover': { backgroundColor: '#E1A713' },
          }}
        >
          Support with PayPal
        </Button>
        <Typography
          variant="body1"
          sx={{
            textAlign: 'justify',
            padding: '0 10px',
          }}
        >
          Your contribution helps us stay ad-free and open-source. Support us with whatever 6 beers are worth in your local supermarket!
        </Typography>
      </ResponsiveRow>

      {/* Amazon Affiliate Section */}
      <ResponsiveRow>
        <Typography
          variant="body1"
          sx={{
            textAlign: 'justify',
            padding: '0 10px',
          }}
        >
          Use our Amazon affiliate link to support us while you shop. Simply click the button below before your next Amazon purchase.
          Your support doesn’t cost you anything extra and helps us grow.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ display: 'block', margin: '0 auto' }}
          onClick={() => window.open('https://www.amazon.com/?tag=yourcode-20', '_blank')}
        >
          Shop on Amazon
        </Button>
      </ResponsiveRow>

      {/* Patreon Section */}
      <ResponsiveRow>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            backgroundColor: '#E85D75',
            '&:hover': { backgroundColor: '#CF4D67' },
          }}
        >
          Support on Patreon
        </Button>
        <Typography
          variant="body1"
          sx={{
            textAlign: 'justify',
            padding: '0 10px',
          }}
        >
          Join us on Patreon for exclusive perks, including early beta access, special insights, and advanced features from Connected-Brewery.
        </Typography>
      </ResponsiveRow>

      {/* GitHub Section */}
      <ResponsiveRow>
        <Typography
          variant="body1"
          sx={{
            textAlign: 'justify',
            padding: '0 10px',
          }}
        >
          Star our GitHub repositories, contribute code, or engage with the community. Your involvement helps us improve and expand
          our offerings for everyone.
        </Typography>
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<GitHub />}
          sx={{
            borderColor: '#2b2d42',
            color: '#2b2d42',
            '&:hover': { backgroundColor: '#F0F4F8' },
          }}
        >
          Visit GitHub
        </Button>
      </ResponsiveRow>

      {/* Feedback Section */}
      <ResponsiveRow>
        <Box
          sx={{
            backgroundColor: '#D3D3D3',
            width: { xs: '100%', sm: '50%' },
            height: '200px',
            maxWidth: '400px',
            borderRadius: '8px',
          }}
        />
        <Button
          variant="contained"
          color="success"
          startIcon={<Feedback />}
          sx={{
            marginLeft: '20px',
            backgroundColor: '#4CAF50',
            '&:hover': { backgroundColor: '#3E8E41' },
          }}
        >
          Send Feedback
        </Button>
      </ResponsiveRow>

      {/* Merch Section */}
      <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '40px' }}>
        Coming soon: Merch! Stay tuned for T-shirts, mugs, and more to show off your love for homebrewing.
      </Typography>
    </Box>
  );
};

/** A responsive row layout component */
const ResponsiveRow = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
      gap: '20px',
    }}
  >
    <Box sx={{ flex: '1', textAlign: 'center' }}>{children[0]}</Box>
    <Box sx={{ flex: '1', textAlign: 'center' }}>{children[1]}</Box>
  </Box>
);

export default SupportProject;
