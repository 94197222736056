import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../global.css';
import loginBackground from '../assets/images/login_background_image.jpg';
import GoogleIcon from '@mui/icons-material/Google';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSignUp && !termsAccepted) {
      alert('You must accept the Terms & Conditions and Privacy Policy to sign up.');
      return;
    }
    setShowModal(true);
    // Handle login or signup logic here
  };

  const toggleSignUp = () => {
    setIsSignUp(!isSignUp);
    setTermsAccepted(false); // Reset termsAccepted state when toggling
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleGoogleSignIn = () => {
    setShowModal(true);
  };

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to top, rgba(250, 249, 241, 0.8), rgba(250, 249, 241, 0.3)), url(${loginBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '5em',
        color: 'rgb(250, 249, 241)',
      }}
    >
      {/* Welcome Box */}
      <div
        style={{
          width: '100%',
          maxWidth: '500px',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          borderRadius: '10px',
          padding: '20px',
          paddingTop: '40px',
          paddningBottom: '40px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
          marginLeft: '-40px', // Moves box outside the browser window
        }}
      >
        <h2 style={{ textAlign: 'center', color: '#fcfcf8', marginBottom: '10px' }}>
          {isSignUp ? 'Create an Account' : 'Welcome Back!'}
        </h2>
        <p style={{ textAlign: 'center', color: '#fcfcf8', fontSize: '14px', marginBottom: '20px' }}>
          {isSignUp
            ? 'Sign up to join the brewing community and unlock amazing tools.'
            : 'Sign in to continue or create an account to join the brewing community.'}
        </p>

        {/* Google Sign-In Button */}
        {!isSignUp && (
          <button
          type="button"
            onClick={handleGoogleSignIn}
          style={{
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            backgroundColor: 'transparent',
            color: '#ffffff',
            border: '#FFFFFF 1PX solid',
            borderRadius: '5px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '24px',
            marginBottom: '20px',
          }}
        >
          <GoogleIcon />
          Sign in with Google
        </button>
        )}

        {/* Or Divider */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
            color: '#fcfcf8',
          }}
        >
          <hr style={{ flex: 1, border: 'none', borderTop: '1px solid #ccc' }} />
          <span style={{ padding: '0 10px', fontSize: '14px' }}>or</span>
          <hr style={{ flex: 1, border: 'none', borderTop: '1px solid #ccc' }} />
        </div>

        {/* Email Login/Signup Form */}
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="email" style={{ display: 'block', marginBottom: '5px', color: '#fcfcf8' }}>
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                width: '100%',
                padding: '10px',
                fontSize: '14px',
                borderRadius: '5px',
                border: '1px solid #ccc',
              }}
              required
            />
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="password" style={{ display: 'block', marginBottom: '5px', color: '#fcfcf8' }}>
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                width: '100%',
                padding: '10px',
                fontSize: '14px',
                borderRadius: '5px',
                border: '1px solid #ccc',
              }}
              required
            />
          </div>

          {/* Terms & Conditions */}
          {isSignUp && (
            <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                id="terms"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                style={{ marginRight: '10px' }}
              />
              <label htmlFor="terms" style={{ color: '#fcfcf8', fontSize: '12px' }}>
                I read and agree to the{' '}
                <Link to="/tnc" style={{ color: '#FABC18', textDecoration: 'underline' }}>
                  Terms & Conditions
                </Link>{' '}
                and{' '}
                <Link to="/privacy" style={{ color: '#FABC18', textDecoration: 'underline' }}>
                  Privacy Policy
                </Link>.
              </label>
            </div>
          )}
          {!isSignUp && (
            <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
              <label htmlFor="terms" style={{ color: '#fcfcf8', fontSize: '12px' }}>
                When logging in, you accept the current{' '}
                <Link to="/tnc" style={{ color: '#FABC18', textDecoration: 'underline' }}>
                  Terms & Conditions
                </Link>{' '}
                and{' '}
                <Link to="/privacy" style={{ color: '#FABC18', textDecoration: 'underline' }}>
                  Privacy Policy
                </Link>.
              </label>
            </div>
          )}

          {/* Login/Sign Up Button */}
          <button
            type="submit"
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '16px',
              backgroundColor: '#2b2d42',
              color: '#ffffff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            {isSignUp ? 'Sign Up, Get Verification Email' : 'Log In'}
          </button>
        </form>

        {/* Call to Action */}
        <p style={{ fontSize: '14px', color: '#fcfcf8', textAlign: 'center', marginTop: '20px' }}>
          {isSignUp ? (
            <>
              Already have an account?{' '}
              <button
                onClick={toggleSignUp}
                style={{
                  background: 'none',
                  border: 'none',
                  color: '#FABC18',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                Log in here
              </button>
            </>
          ) : (
            <>
              Don’t have an account?{' '}
              <button
                onClick={toggleSignUp}
                style={{
                  background: 'none',
                  border: 'none',
                  color: '#FABC18',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                Sign up here
              </button>
            </>
          )}
        </p>
      </div>

      {/* Modal */}
      {showModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '10px',
              textAlign: 'center',
            }}
          >
            <h2>Coming Soon</h2>
            <p>Thanks for being ahead of your time and excited. Coming soon!</p>
            <button
              onClick={closeModal}
              style={{
                marginTop: '10px',
                padding: '10px 20px',
                backgroundColor: '#2b2d42',
                color: '#ffffff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
