import React from 'react';
import BrewEducationSidebar from '../components/EducationSidebar';
import LoginCallToAction from '../components/LoginCallToAction';
import EducationDisclaimer from '../components/EducationDisclaimer';
import Signup from '../components/Signup';
import { Outlet, useLocation } from 'react-router-dom';
import '../global.css';

const BrewEducation = () => {
    const location = useLocation();
    const isLandingPage = location.pathname === '/brew-education';

    return (
        <div className="tools-layout" style={{ backgroundColor: '#FAF9F1', padding: '20px' }}>
            {/* Sidebar */}
            <BrewEducationSidebar />
            <div className="tools-content" style={{ backgroundColor: '#FAF9F1', paddingLeft: '24px', paddingRight: '40px' }}>
                {isLandingPage ? (
                    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
                        <h1 style={{ textAlign: 'center', fontSize: '2.5rem', color: '#2b2d42' }}>Brew Education</h1>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Welcome to the forefront of homebrewing innovation! You’re among the first to explore our Brew Education
                            hub, a space dedicated to empowering brewers with actionable insights and tools. By being here, you're
                            joining a vibrant community passionate about mastering the science behind brewing. Don't miss out—sign up
                            for our newsletter to stay ahead of the curve and receive exclusive updates!
                        </p>
                        {/* Newsletter Signup */}
                        <Signup />
                        <h2 style={{ marginTop: '30px', fontSize: '2rem', color: '#2b2d42' }}>Brewing Basics: A Scientific Journey</h2>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Brewing isn’t just a craft—it’s a precise science. At Has A Brewery, we believe in consistency,
                            repeatability, and achieving excellent quality in every batch. By understanding the variables at each stage,
                            you can produce beer that isn’t a guessing game but a predictable masterpiece.
                        </p>
                        <h3 style={{ marginTop: '20px', fontSize: '1.5rem', color: '#2b2d42' }}>1. General Process</h3>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            The brewing process is a fascinating combination of science, precision, and craftsmanship. It transforms four basic
                            ingredients—water, malt, hops, and yeast—into the rich variety of beer styles enjoyed worldwide. While the ingredients
                            may seem simple, the process is where the magic happens, requiring attention to detail and control over each variable
                            to ensure a consistent and high-quality result.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Brewing begins with the selection of ingredients. The type of malt determines the beer’s body and color, hops contribute
                            bitterness and aroma, and yeast plays a critical role in fermentation, turning sugars into alcohol and carbon dioxide.
                            Water, often overlooked, is the most abundant ingredient, and its mineral content can have a profound impact on the
                            beer's flavor.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            From milling and mashing to boiling and fermenting, each step in the brewing process builds on the previous one. This
                            chain of steps is carefully designed to extract sugars, develop flavors, and provide the right conditions for yeast to
                            thrive. Precision is key—temperatures, pH levels, and timing all play a role in creating the desired beer profile.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Brewing is a balance of science and creativity. While recipes and processes are grounded in science, the brewer's
                            choices—such as which hops to use or how long to condition the beer—allow for endless experimentation and innovation.
                            Whether brewing a crisp lager, a hoppy IPA, or a complex stout, mastering the general process is the foundation of
                            creating exceptional beer.
                        </p>

                        <h3 style={{ marginTop: '20px', fontSize: '1.5rem', color: '#2b2d42' }}>2. Water Preparation</h3>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Water is often referred to as the foundation of beer. Since it makes up more than 90% of the final product, its quality
                            and composition are critical to the brewing process. Not all water is created equal, and different styles of beer
                            require different mineral balances to achieve their characteristic flavors.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            The first step in water preparation is understanding your water source. Tap water, well water, and bottled water can
                            have varying mineral contents and pH levels. If you're unsure about your water's composition, consider having it
                            analyzed or review a water quality report from your municipality. Our water profile tools can help you analyze and
                            adjust your water to suit the specific needs of your recipe.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Brewers commonly focus on key components such as calcium, magnesium, sulfate, chloride, sodium, and bicarbonate. Each
                            mineral plays a unique role in the brewing process. For example, calcium aids in enzyme activity during mashing and
                            enhances the beer's stability, while sulfate emphasizes hop bitterness. On the other hand, chloride contributes to a
                            fuller mouthfeel and balances flavors.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Beyond mineral content, pH is another crucial factor. The ideal mash pH typically falls between 5.2 and 5.6, which
                            promotes optimal enzyme activity and enhances flavor development. Adjusting pH can be achieved by adding food-grade
                            acids, such as lactic acid or phosphoric acid, or by using water treatments like gypsum or calcium chloride.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Tailoring your water profile to match the beer style is key to achieving authentic flavors. For example, a soft water
                            profile with low mineral content is ideal for brewing delicate lagers, while a higher sulfate-to-chloride ratio suits
                            hop-forward styles like IPAs. With our water profile calculators and guides, you can confidently adjust your water to
                            bring out the best in your brew.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Remember, water preparation is not just about mimicking traditional water profiles—it's about understanding your
                            ingredients and creating a balanced foundation for the beer you envision. By taking the time to prepare your water,
                            you'll set yourself up for success and produce consistently high-quality beer.
                        </p>

                        <h3 style={{ marginTop: '20px', fontSize: '1.5rem', color: '#2b2d42' }}>3. Milling the Grains</h3>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Milling is the critical first step in preparing your malted grains for the mashing process. It involves cracking open
                            the grains to expose the starches inside while preserving the integrity of the husks. The husks play a crucial role
                            in creating a natural filter bed during lautering, so it's essential not to crush them too finely.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            The objective of milling is to strike the right balance between breaking the grain enough to maximize starch exposure
                            and avoiding over-crushing, which can lead to a gummy mash or lautering issues. When the grains are milled correctly,
                            they allow for efficient enzymatic conversion of starches to fermentable sugars during mashing.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Most homebrewers use a roller mill, which allows for adjustable settings to achieve the desired level of crush. The
                            ideal mill gap depends on your brewing system and grain type, but a typical setting ensures that the kernel is
                            sufficiently cracked while the husks remain largely intact. You can test your crush by examining the milled grains:
                            the endosperm should be finely broken, and the husks should look mostly whole.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            For all-grain brewers, proper milling directly impacts mash efficiency, which refers to how effectively sugars are
                            extracted from the grains. An inconsistent or overly fine crush can lead to stuck sparges, while an under-crushed
                            grain bill might result in poor sugar extraction and lower-than-expected efficiency.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            It's also important to note that the milling process generates dust, which can be a potential hazard in enclosed
                            spaces. Always mill your grains in a well-ventilated area and consider using a grain mill with a dust collection
                            system or a cover to minimize airborne particles.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            For those using specialty grains or adjuncts, milling might require special attention. For instance, rice hulls can
                            be added to the mash to improve lautering when dealing with adjunct-heavy grain bills. Additionally, pre-crushed
                            grains are available from suppliers for brewers who don't own a mill, but freshly milled grains generally offer the
                            best results.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            By mastering the milling process, you'll set a solid foundation for the rest of your brew day, ensuring that your
                            grains are prepared for efficient and trouble-free mashing. Milling might seem simple, but attention to detail at
                            this stage can make a significant difference in the quality and consistency of your final beer.
                        </p>

                        <h3 style={{ marginTop: '20px', fontSize: '1.5rem', color: '#2b2d42' }}>4. Mashing</h3>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Mashing is the heart of the brewing process, where the magic of enzyme activity transforms the starches locked inside
                            the grains into fermentable sugars. This step determines the sugar profile of your wort, which in turn affects the
                            alcohol content, body, and sweetness of your final beer. Mashing is as much a science as it is a process, demanding
                            precision, patience, and a keen understanding of the chemistry at play.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            The process involves mixing your milled grains with hot water in a vessel called a mash tun. The heat activates enzymes
                            naturally present in the malt, such as alpha-amylase and beta-amylase, which break down complex starch molecules into
                            simpler fermentable sugars like maltose. This process is critical for ensuring your yeast has the necessary fuel to
                            produce alcohol and carbon dioxide during fermentation.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Temperature control during mashing is vital. Most mashing processes occur between 62°C and 72°C (143°F to 162°F),
                            depending on the beer style and desired characteristics. Lower temperatures favor beta-amylase activity, resulting in
                            a lighter, drier beer, while higher temperatures activate alpha-amylase, producing a fuller-bodied brew. Many brewers
                            employ step mashing, gradually raising the temperature through a series of rests to maximize enzyme activity and
                            extract a complex range of sugars.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Maintaining a consistent water-to-grain ratio, typically around 2.5-3 liters of water per kilogram of grain
                            (or 1.25 quarts per pound), is also important. Too much water can dilute the mash, while too little can hinder
                            enzyme activity. Stirring the mash periodically helps prevent clumps and ensures even heat distribution.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            To determine when mashing is complete, brewers often use an iodine test. A small sample of wort is mixed with a drop
                            of iodine; if it turns blue or black, starch is still present, and mashing needs more time. A lack of color change
                            indicates complete starch conversion.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            After the mashing step, the mash is heated to a temperature of around 75°C (167°F) for mash-out. This halts enzymatic
                            activity and makes the sugars more fluid, improving their extraction during lautering. Skipping this step can affect
                            your brewhouse efficiency and the clarity of the resulting wort.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            By mastering the mashing process, brewers gain control over the flavor, alcohol content, and body of their beer. It’s
                            a step where meticulous attention to detail pays dividends, transforming raw ingredients into the liquid foundation
                            of a great brew.
                        </p>

                        <h3 style={{ marginTop: '20px', fontSize: '1.5rem', color: '#2b2d42' }}>5. Boiling and Hopping</h3>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            The boiling stage is a pivotal moment in the brewing process, where science and precision converge to define the
                            character of your beer. Boiling sterilizes the wort, ensuring a clean, contamination-free environment for fermentation.
                            It also enables the extraction of bitterness, flavor, and aroma from hops, setting the foundation for your beer’s
                            overall flavor profile.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            During boiling, the wort is typically heated to a vigorous boil for 60 to 90 minutes. This step accomplishes several
                            key objectives. First, it drives off unwanted volatile compounds such as dimethyl sulfide (DMS), which can impart
                            off-flavors. Second, it coagulates proteins and tannins, forming a substance called trub, which is later removed to
                            enhance the clarity of the beer. Finally, boiling is essential for isomerizing alpha acids from hops, which provides
                            the characteristic bitterness in beer.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Hops are added at specific intervals during the boil, each addition serving a distinct purpose. Bittering hops are
                            typically added early in the boil, often at the 60-minute mark. These hops contribute to the beer's perceived bitterness
                            and balance the sweetness of the malt. As the boil progresses, flavor hops are introduced, typically around the 15- to
                            30-minute mark, to impart subtle herbal, fruity, or floral notes. Finally, aroma hops are added in the last 5 minutes
                            or at flameout to preserve their delicate aromatic compounds.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Advanced techniques, such as hop bursting and whirlpool hopping, allow brewers to extract maximum flavor and aroma
                            without excessive bitterness. Hop bursting involves adding a large volume of hops late in the boil, while whirlpool
                            hopping introduces hops after the boil during the cooling phase. Both techniques emphasize aroma and flavor intensity,
                            making them popular among craft brewers.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            It’s crucial to measure the International Bitterness Units (IBUs) of your brew, which quantifies the amount of bitterness
                            contributed by the hops. Our <strong>IBU Calculator</strong> can help you fine-tune your hop additions to match your
                            desired beer style.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Proper equipment, such as a well-maintained kettle and a reliable heat source, is critical during this stage. Using
                            high-quality hops stored under ideal conditions ensures freshness and consistency in your brew. For an even boil, stir
                            the wort occasionally to prevent scorching and promote even heat distribution.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            By mastering the boiling and hopping stage, brewers gain unparalleled control over the bitterness, flavor, and aroma of
                            their beer. Remember, brewing is a science, and precise control during this stage lays the groundwork for a flavorful
                            and well-balanced brew.
                        </p>

                        <h3 style={{ marginTop: '20px', fontSize: '1.5rem', color: '#2b2d42' }}>6. Cooling</h3>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Cooling the wort after boiling is a critical step in the brewing process, where timing and technique make all the
                            difference. Rapid cooling is essential for two main reasons: it minimizes the risk of contamination from airborne
                            microbes and prepares the wort for yeast pitching at an appropriate temperature. The faster the wort is cooled, the
                            less opportunity there is for unwanted bacteria or wild yeast to take hold, ensuring a clean fermentation.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Effective cooling also helps achieve a process known as cold break, where proteins and other particles coagulate and
                            settle out of the wort. This step contributes to a clearer final product with improved stability and shelf life. Failure
                            to cool quickly can result in haze, off-flavors, and diminished beer quality.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            There are several methods brewers can use to cool their wort, with immersion chillers, counterflow chillers, and plate
                            chillers being the most popular options. An immersion chiller is placed directly into the hot wort, with cold water
                            circulating through its coils to lower the temperature. Counterflow chillers and plate chillers operate by passing the
                            hot wort through a coil or series of plates while cold water flows in the opposite direction, achieving rapid cooling.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            The target temperature for cooling depends on the type of yeast being used. For ales, the wort should be cooled to
                            around 18–22°C (64–72°F), while lagers require temperatures closer to 7–13°C (45–55°F). Precision is key here, as
                            pitching yeast into wort that is too hot can kill the yeast or produce off-flavors, while temperatures that are too low
                            can slow fermentation.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            To ensure optimal cooling, maintain clean and sanitized equipment, as the wort is highly susceptible to contamination
                            at this stage. Additionally, stirring the wort during cooling improves heat exchange efficiency and speeds up the process.
                            For brewers aiming for greater precision, investing in a thermometer probe to monitor the cooling progress can be
                            invaluable.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Remember, cooling is not just about preparing for fermentation—it's a vital step that lays the foundation for a clean,
                            stable, and high-quality beer. By mastering the cooling process, brewers can elevate their craft and produce beer that
                            is both delicious and consistent.
                        </p>

                        <h3 style={{ marginTop: '20px', fontSize: '1.5rem', color: '#2b2d42' }}>7. Fermentation</h3>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Fermentation is where the magic of brewing truly happens. During this stage, yeast consumes the fermentable sugars
                            in the wort and converts them into alcohol, carbon dioxide, and a wide array of flavor compounds. The yeast's metabolic
                            activity is what transforms your carefully prepared wort into beer.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            The fermentation process begins immediately after yeast is pitched into the cooled wort. Within a few hours, the yeast
                            enters an active phase known as primary fermentation, during which the majority of alcohol is produced. This stage
                            typically lasts between 3 to 7 days, depending on factors such as yeast strain, fermentation temperature, and beer style.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Temperature control is critical during fermentation, as it directly influences the yeast’s performance and the resulting
                            beer’s flavor profile. A stable temperature within the recommended range for your yeast strain ensures consistent
                            fermentation. For example, ale yeast thrives at 18–22°C (64–72°F), while lager yeast prefers cooler conditions of
                            7–13°C (45–55°F). Fluctuations or extremes in temperature can stress the yeast, leading to off-flavors or stalled
                            fermentation.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            During fermentation, a layer of foam, known as krausen, forms on the surface of the wort, indicating active yeast
                            metabolism. It’s important to allow enough headspace in your fermenter to accommodate this foam and prevent spills.
                            An airlock or blow-off tube ensures that excess carbon dioxide can escape while keeping contaminants out.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Once primary fermentation slows down, the yeast enters a cleanup phase where it metabolizes off-flavors such as
                            diacetyl and acetaldehyde. Patience is key during this phase, as allowing the beer to sit for a few extra days improves
                            its flavor and stability.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Monitoring gravity readings with a hydrometer or refractometer is the best way to determine when fermentation is
                            complete. Consistent readings over two or three days indicate that the yeast has finished its work. Avoid opening
                            the fermenter unnecessarily to minimize the risk of oxidation or contamination.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Fermentation is as much about science as it is about understanding yeast behavior. With precise control and monitoring,
                            you can ensure that this critical stage delivers the flavors, aromas, and alcohol content that define your beer. It’s the
                            heart of brewing and a step where small improvements can make a big difference in the quality of your final product.
                        </p>

                        <h3 style={{ marginTop: '20px', fontSize: '1.5rem', color: '#2b2d42' }}>8. Conditioning</h3>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Conditioning is the final step where your beer undergoes maturation to develop its optimal flavor, aroma, and carbonation.
                            This stage allows the beer to stabilize, off-flavors to mellow, and finer nuances to shine through, ensuring that every sip
                            meets your expectations for quality and consistency.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Depending on the beer style and equipment, conditioning can take place in bottles, kegs, or specialized conditioning tanks.
                            In the case of bottle conditioning, a small amount of priming sugar is added before sealing the bottles. This sugar feeds
                            residual yeast, producing carbon dioxide that naturally carbonates the beer over time. Keg conditioning involves a similar
                            process, but carbonation is typically achieved through forced CO₂ injection for faster results and precise control.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            The duration of the conditioning phase varies by beer style. Light, hoppy beers like IPAs may benefit from a short
                            conditioning period of one to two weeks to preserve their vibrant hop character. Conversely, complex styles such as stouts,
                            barleywines, or Belgian ales may require months to fully develop their intricate flavors and aromas.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Temperature control is vital during conditioning. Cooler temperatures between 2–5°C (36–41°F) are ideal for lagering,
                            which enhances clarity and crispness. Warmer conditioning temperatures around 10–15°C (50–59°F) are suitable for ales,
                            facilitating the integration of flavors. Maintaining consistent temperatures helps avoid unwanted flavor fluctuations
                            and ensures a smooth maturation process.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            The clarity of your beer improves during conditioning as suspended particles like proteins, hop debris, and yeast cells
                            settle out. Many brewers use fining agents or filtration systems to accelerate this process, resulting in a polished and
                            visually appealing final product. However, some styles, like hazy IPAs, intentionally retain these particles for their
                            distinctive appearance and mouthfeel.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Tasting your beer periodically during conditioning is an excellent way to understand how flavors evolve over time.
                            However, avoid frequent sampling, especially during bottle conditioning, as this can disrupt carbonation and expose
                            your beer to oxygen.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Whether you're producing a crisp pilsner, a bold stout, or a fruity ale, the conditioning phase is an essential step that
                            transforms good beer into exceptional beer. It’s a process where patience and precision pay off, culminating in a brew
                            that you can take pride in sharing with friends, family, or even the world.
                        </p>

                        <h3 style={{ marginTop: '20px', fontSize: '1.5rem', color: '#2b2d42' }}>9. Drinking</h3>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            The most anticipated and rewarding step of the brewing journey—drinking your creation! After weeks of meticulous preparation,
                            fermentation, and conditioning, it’s finally time to pour your brew and savor the fruits of your labor. This is where all
                            the effort comes together, and you get to experience the taste, aroma, and mouthfeel of your beer.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Start by pouring your beer into a clean glass that complements the style. The right glassware can enhance the aroma and
                            flavor profile, whether it’s a tulip for IPAs, a pint for stouts, or a snifter for high-gravity ales. Pay attention to the
                            head formation and retention as you pour—a good foam cap is often a sign of proper carbonation and balance.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Take a moment to appreciate the appearance of your beer. Note its clarity (or intentional haziness), the color, and the
                            brilliance of the liquid as light passes through. Swirl the glass gently to release the beer’s aromatic compounds and
                            take a deep inhale to identify the bouquet of scents—from citrusy hops to malty sweetness or fruity esters.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            The first sip is the ultimate reward. Allow the flavors to coat your palate, and notice the interplay of sweetness,
                            bitterness, acidity, and carbonation. Consider the mouthfeel—is it light and crisp or rich and velvety? These sensory
                            details not only deepen your appreciation of the beer but also provide valuable feedback for refining future batches.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Drinking is not just about enjoyment but also about learning and sharing. Invite friends and fellow brewers to taste your
                            beer and provide constructive feedback. A well-hosted tasting session can be both an educational and social experience,
                            sparking discussions about brewing techniques, ingredient choices, and personal preferences.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Remember to take detailed notes during this stage. Document your impressions of the beer’s flavor, aroma, appearance, and
                            overall quality. Consider what worked well and what could be improved. These notes will be invaluable when tweaking recipes
                            or experimenting with new styles.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            While this step is about celebration, moderation is key. Enjoy your brew responsibly, and take pride in knowing that you’ve
                            contributed to a craft steeped in tradition and innovation. Whether it’s your first batch or your hundredth, every sip is
                            a testament to your skill, dedication, and passion for brewing.
                        </p>

                        <h3 style={{ marginTop: '20px', fontSize: '1.5rem', color: '#2b2d42' }}>10. Testing and Learning</h3>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Testing your beer is a crucial step in the brewing journey, providing valuable insights into the quality and consistency of your process. By measuring and analyzing key attributes—such as color, bitterness, clarity, and flavor—you can identify strengths and areas for improvement in your brew.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Begin by evaluating the beer’s appearance. Check the color against the expected range for your style, and note the clarity. A hazy IPA may be perfect for its category, while a pilsner should be crystal clear. Consistency in these visual markers often reflects precision in earlier steps, such as water profile adjustments and proper filtration.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Next, assess the beer’s bitterness and flavor profile. Bitterness is influenced by the type, amount, and timing of hop additions during the boil. Use tools like our <strong>IBU Calculator</strong> to predict and refine bitterness levels. For flavor, rely on both sensory perception and input from others. Descriptive feedback on sweetness, tartness, or off-flavors can guide recipe adjustments.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Accurate testing often requires the use of measurement tools. A hydrometer or refractometer helps determine specific gravity, which is vital for calculating alcohol content, attenuation, and fermentation efficiency. If you’ve taken readings at a temperature different from your hydrometer’s calibration, use our <strong>Hydrometer Temperature Correction Tool</strong> to adjust your values for accuracy.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Calculating the ABV of your beer is another critical test. By combining the initial and final gravity readings, you can determine how much sugar was converted into alcohol. Our <strong>ABV Calculator</strong> simplifies this process, allowing you to compare your results to the target values and verify your fermentation success.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Testing isn’t just about identifying flaws; it’s an opportunity to celebrate what’s working well. Note the elements that align with your goals—be it a perfectly balanced bitterness, a smooth mouthfeel, or a spot-on ABV—and replicate those successes in future batches.
                        </p>
                        <p style={{ textAlign: 'justify', lineHeight: '1.6', fontSize: '1.2rem', color: '#4a4a4a' }}>
                            Lastly, remember that brewing is an ongoing learning process. Even experienced brewers encounter challenges and experiment with new techniques. Stay curious, embrace feedback, and continue refining your craft. Each batch is a step toward brewing excellence, where science meets passion and precision drives creativity.
                        </p>

                    </div>
                ) : (
                    <Outlet />
                )}
                <div style={{ margin: '20px 0' }}></div>
                <hr />
                {/* Login Call to Action */}
                <div className="login-call-to-action">
                    <LoginCallToAction />
                </div>
                <hr />
                {/* Tools Disclaimer */}
                <div className="tools-disclaimer">
                    <EducationDisclaimer />
                </div>
            </div>
        </div>
    );
};

export default BrewEducation;
