import React from 'react';
import '../global.css';

const LoginCallToAction = () => {
  return (
    
      <p>
        If you want to dive even deeper into the magic, sign up for free!
        As a homebrewer, connect your brewery so your friends can proudly say that{' '}
        <span className="highlighted-text">{'{your_name}'}.HasABrewery.com</span>! <br />
        Best of all, it is and will always stay free for homebrewers.
      </p>
  );
};

export default LoginCallToAction;
