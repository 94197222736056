import React from 'react';
import { Helmet } from 'react-helmet';
import { Element } from 'react-scroll';
import Landing from '../components/Landing';
import AboutProject from '../components/AboutProject';
import Partners from '../components/Partners';
import Signup from '../components/Signup';
import Other from '../components/Other';
import ScrollToTopButton from '../components/ScrollToTopButton';

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Home - Has A Brewery</title>
        <meta name="description" content="Welcome to Has A Brewery, your go-to platform for homebrewing tools and resources." />
        <meta property="og:title" content="Home - Has A Brewery" />
        <meta property="og:description" content="Discover homebrewing tools and resources to enhance your brewing experience." />
        <meta property="og:image" content="/images/home-thumbnail.jpg" />
        <meta property="og:url" content="https://hasabrewery.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Home - Has A Brewery" />
        <meta name="twitter:description" content="Welcome to Has A Brewery, your go-to platform for homebrewing tools and resources." />
        <meta name="twitter:image" content="/images/home-thumbnail.jpg" />
      </Helmet>
      {/* Main Content */}
      <Landing />
      <div className="container">
        <AboutProject />
        <Partners />
        <Element name="signup-section">
          <Signup />
        </Element>
        <Other />
        <ScrollToTopButton />
      </div>
    </div>
  );
};

export default Home;
