import React from 'react';

const GetStartedDisclaimer = () => {
  return (
    <div>
      <p style={{ fontStyle: 'italic', fontSize: '0.9rem', color: '#6c757d', lineHeight: '1.5' }}>
        <strong>Important Notice:</strong> The tools and resources provided by Has A Brewery are intended for
        personal and homebrewing use only. They are not approved for use in commercial brewing operations. If you are a
        commercial brewer, please explore our professional-grade solutions at{' '}
        <a href="https://connected-brewery.com" target="_blank" rel="noopener noreferrer" style={{ color: '#007bff' }}>
          Connected-Brewery.com
        </a>.
        <br />
        Our tools and calculators are community-based and should be used with great care. Please note that not all systems
        have been extensively tested by the Has A Brewery team. While we strive for accuracy, these tools are provided "as-is"
        and may contain errors. Always double-check calculations and use professional methods for critical brewing processes.
      </p>

    </div>
  );
};

export default GetStartedDisclaimer;
