import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, List, ListItem, ListItemText, Collapse, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import '../global.css';

const GetStartedSidebar = () => {
    const [openSection, setOpenSection] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const sidebarRef = useRef(null);

    const handleToggleSection = () => {
        setOpenSection(!openSection);
    };

    const handleToggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setSidebarOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <button className="sidebar-toggle" onClick={handleToggleSidebar}>
                {sidebarOpen ? '<' : '>'}
            </button>
            <div className={`tools-sidebar ${sidebarOpen ? 'open' : ''}`} ref={sidebarRef}>
                <List>
                    <ListItem button onClick={handleToggleSection}>
                        <ListItemText
                            primary={
                                <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    Equipment
                                </span>
                            }
                        />
                        {openSection ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSection} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <hr></hr>
                            <ListItem button component={NavLink} to="/get-started" onClick={handleToggleSidebar}>
                                <ListItemText primary="Coming Soon" className="sidebar-link" />
                            </ListItem>
                            <hr></hr>
                            <hr></hr>
                        </List>
                    </Collapse>
                </List>
            </div>
        </>
    );
};

export default GetStartedSidebar;
