// src/context/UnitContext.js
import React, { createContext, useState, useContext } from 'react';
import { logImperialSwitch, logMetricSwitch } from '../utils/FreedomSelector';

const UnitContext = createContext();

export const UnitProvider = ({ children }) => {
  const [unit, setUnit] = useState('Metric'); // Default to Metric

  const switchToImperial = () => {
    logImperialSwitch(); // Call the correct function
    setUnit('Imperial');
  };

  const switchToMetric = () => {
    logMetricSwitch(); // Call the correct function
    setUnit('Metric');
  };

  return (
    <UnitContext.Provider value={{ unit, switchToImperial, switchToMetric }}>
      {children}
    </UnitContext.Provider>
  );
};

export const useUnit = () => {
  const context = useContext(UnitContext);
  if (!context) {
    throw new Error('useUnit must be used within a UnitProvider');
  }
  return context;
};
