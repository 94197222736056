import React from 'react';
import { Box, Stack, Button } from '@mui/material';
const ron404Gif = process.env.PUBLIC_URL + '/assets/icons/ron-404.gif';


const NotFoundPage = () => {
return (
    <Box
        className="section"
        sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            
            padding: { xs: '20px', md: '40px' },
            minHeight: '95vh',
            gap: { xs: '20px', md: '40px' },
        }}
    >
        <Stack
        
            sx={{
                flex: 1,
                paddingLeft: { xs: '0', md: '24px' },
                
                alignItems: 'center',
            }}
        >
            <div className="vertical-space-24"></div>
            
            <h1>Page Not Found</h1>
            <h4>Did you look for a recipe for alcohol free beer?! <br/>Ron does not approve.</h4>
            <p>
                We assume it was an honest mistake. <br />
                Ron wouldn't approve you looking for something silly like a recipe to make an alcohol free beer. <br/>
                That is even worse than lying. An alcohol free beer is water that is lying about being beer.<br/>
            </p>
            <p>Let's take out the guessing game and get into the brew science!</p>
            <div className="vertical-space-24"></div>

            <Button
                href="/"
                sx={{
                    marginTop: '10px',
                    minWidth: '150px',
                    width: {xs: '350px', lg: '40%'},
                    justifyContent: 'center',
                    marginLeft: {xs: '0px', lg: '30%'},
                    border: '1px solid #2b2d42',
                    color: '#2b2d42', // Font color
                    '&:hover': {
                        backgroundColor: '#FABC18',
                        color: '#2b2d42',
                    },
                }}
            >
                 Go Home, Son.
            </Button>
        </Stack>
        <Stack
            
            sx={{
                flex: 1,
                alignItems: 'center',
            }}
        >
            <div className="vertical-space-24"></div>
            <div className="vertical-space-24"></div>
            <div className="vertical-space-24"></div>
            <div className="vertical-space-24"></div>
            <Box
                component="img"
                src={ron404Gif}
                alt="Landing Image"
                sx={{
                    width: { xs: '100%', md: '450px' },
                    maxWidth: '100%',
                    borderRadius: '12px',
                    objectFit: 'cover',
                }}
            />
            <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
            </Box>
        </Stack>
    </Box>
);
};

export default NotFoundPage;
