import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, List, ListItem, ListItemText, Collapse, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import '../global.css';

const ToolsSidebar = () => {
    const [openCalculators, setOpenCalculators] = useState(false);

    const [openCharts, setOpenCharts] = useState(false);

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const sidebarRef = useRef(null);

    const handleToggleCalculators = () => {
        setOpenCalculators(!openCalculators);

    };



    const handleToggleCharts = () => {
        setOpenCharts(!openCharts);

    };



    const handleToggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);

    };



    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setSidebarOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <button className="sidebar-toggle" onClick={handleToggleSidebar}>
                {sidebarOpen ? '<' : '>'}
            </button>
            <div className={`tools-sidebar ${sidebarOpen ? 'open' : ''}`} ref={sidebarRef}>
                <List>
                    {/* BREW CALCULATORS */}
                    <ListItem button onClick={handleToggleCalculators}>
                        <ListItemText
                            primary={
                                <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    Brew Calculators
                                </span>
                            }
                        />
                        {openCalculators ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openCalculators} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <hr></hr>
                            <ListItem button component={NavLink} to="/tools/calculators/abv" onClick={handleToggleSidebar}>
                                <ListItemText primary="ABV Calculator" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/tools/calculators/hydro-temp-correct" onClick={handleToggleSidebar}>
                                <ListItemText primary="Hydrometer Temp. Correct" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/tools/calculators/plato-gravity-converter" onClick={handleToggleSidebar}>
                                <ListItemText primary="Plato to Gravity" className="sidebar-link" />
                            </ListItem>
                            <ListItem button component={NavLink} to="/tools/calculators/color-srm-ebc" onClick={handleToggleSidebar}>
                                <ListItemText primary="EBC / SRM Color" className="sidebar-link" />
                            </ListItem>
                            <hr></hr>
                            <hr></hr>
                        </List>
                    </Collapse>

                    {/* SETUP RECOMMENDATION */}
                    {/* ...existing code... */}

                    {/* Charts & Sheets */}
                    <ListItem button onClick={handleToggleCharts}>
                        <ListItemText
                            primary={
                                <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    Charts & Sheets
                                </span>
                            }
                        />
                        {openCharts ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openCharts} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <hr></hr>
                            <ListItem button component={NavLink} to="/tools/charts/water-profiles" onClick={handleToggleSidebar}>
                                <ListItemText primary="Water Profiles" className="sidebar-link" />
                            </ListItem>
                            <hr></hr>
                            <hr></hr>
                        </List>
                    </Collapse>

                    {/* Other */}
                    {/* ...existing code... */}
                </List>
            </div>
        </>
    );
};

export default ToolsSidebar;
