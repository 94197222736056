import React from 'react';
import { Box, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        padding: '20px',
        maxWidth: '800px',
        margin: '0 auto',
        backgroundColor: '#FAF9F1',
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: '16px', color: '#2b2d42', textAlign: 'center' }}>
        Privacy Policy
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '16px', textAlign: 'justify' }}>
        Your privacy is important to us! This is a placeholder for the Privacy Policy. Use this section to describe how user data
        is collected, stored, and used by your platform, along with measures you take to ensure data security.
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '16px', textAlign: 'justify' }}>
        Please replace this placeholder content with your actual Privacy Policy to ensure compliance with applicable data protection
        laws and build trust with your users.
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
