import React, { useState } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { Helmet } from 'react-helmet';

import { useUnit } from '../../context/UnitContext';
import { styled } from '@mui/system'; // Add this import

const StyledButton = styled(Button)({
  padding: '10px 20px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  backgroundColor: '#2b2d42',
  color: '#f4f1de',
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
  transition: 'background-color 0.3s ease-in-out, transform 0.2s',
  '&:hover': {
    backgroundColor: '#FABC18',
    color: '#2b2d42',
    transform: 'scale(1.15)',
  },
});

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fabc18',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#fabc18',
    },
  },
});

const ABVCalculator = () => {
  const { unit, switchToImperial, switchToMetric } = useUnit();
  const [temperatureScale, setTemperatureScale] = useState('°C, Celsius');
  const [gravityScale, setGravityScale] = useState('Specific Gravity');
  const [og, setOg] = useState('');
  const [fg, setFg] = useState('');
  const [calculationType, setCalculationType] = useState('standard');
  const [results, setResults] = useState({
    abv: '0',
    apparentAttenuation: '0',
    calories: '0',
    og: { gravity: '0', plato: '0' },
    fg: { gravity: '0', plato: '0' },
  });
  const [ogError, setOgError] = useState(false);
  const [fgError, setFgError] = useState(false);

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const validateValue = (value) => {
    const numValue = parseFloat(value);
    if (gravityScale === 'Specific Gavity') {
      if ((numValue >= 0.9 && numValue <= 1.2) || (numValue >= 900 && numValue <= 1200)) {
        return true;
      }
    } else if (gravityScale === 'Plato') {
      if (numValue >= -30 && numValue <= 35) {
        return true;
      }
    }
    return false;
  };

  const correctGravity = (value) => {
    const numValue = parseFloat(value);
    return numValue > 500 ? numValue / 1000 : numValue;
  };

  const calculateResults = () => {
    if (!og || !fg) {
      alert('Please enter valid OG and FG values.');
      return;
    }

    const convertPlatoToSG = (plato) => {
      return 1 + plato / (258.6 - (plato / 258.2) * 227.1);
    };

    const correctInput = (value) => {
      const numValue = parseFloat(value);
      return numValue > 600 ? numValue / 1000 : numValue;
    };

    const ogValue = correctInput(parseFloat(og));
    const fgValue = correctInput(parseFloat(fg));

    let ogGravity = ogValue;
    let fgGravity = fgValue;

    if (gravityScale === 'Plato') {
      ogGravity = convertPlatoToSG(ogValue);
      fgGravity = convertPlatoToSG(fgValue);
    }

    // ABV calculation using specific gravity
    // const abv = ((ogGravity - fgGravity) * 131.25).toFixed(2);
    const abv = calculationType === 'standard'
      ? ((ogGravity - fgGravity) * 131.25).toFixed(2)
      : ((76.08 * (ogGravity - fgGravity)) / (1.775 - ogGravity) * (fgGravity / 0.794)).toFixed(2);

    // Apparent attenuation calculation
    const apparentAttenuation = (((ogGravity - fgGravity) / (ogGravity - 1)) * 100).toFixed(2);

    // Calories calculation (per 12 oz serving for imperial, per 100 ml for metric)
    
    const calories = 
      unit === 'Metric' ? 
      (( 1881.22 * fgGravity * (ogGravity - fgGravity) + 3550 * fgGravity * (1 - fgGravity) )  ).toFixed(2) :
      (( 1881.22 * fgGravity * (ogGravity - fgGravity) + 3550 * fgGravity * (1 - fgGravity) ) * 120 / 29.5735 ).toFixed(2) ;

    const ogPlato =
      gravityScale === 'Plato' ? ogValue : (ogGravity - 1) * 258.6 / (ogGravity - 1);
    const fgPlato =
      gravityScale === 'Plato' ? fgValue : (fgGravity - 1) * 258.6 / (fgGravity - 1);

    setResults({
      abv,
      apparentAttenuation,
      calories,
      og: { gravity: ogGravity.toFixed(3), plato: ogPlato.toFixed(2) },
      fg: { gravity: fgGravity.toFixed(3), plato: fgPlato.toFixed(2) },
    });
  };





  return (

    <div>
      <Helmet>
        <title>ABV Calculator - Has A Brewery</title>
        <meta name="description" content="Calculate your beer's Alcohol By Volume (ABV) with ease. Supports specific gravity and Plato inputs for accurate brewing results." />
        <meta property="og:title" content="ABV Calculator - Has A Brewery" />
        <meta property="og:description" content="Accurately calculate ABV for your brews. Use our beer-specific tools for homebrewing success." />
        <meta property="og:image" content="/images/abv-calculator-thumbnail.jpg" />
        <meta property="og:url" content="https://hasabrewery.com/tools/calculator/abv" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ABV Calculator - Has A Brewery" />
        <meta name="twitter:description" content="Calculate your beer's Alcohol By Volume (ABV) with ease. Supports specific gravity and Plato inputs for accurate brewing results." />
        <meta name="twitter:image" content="/images/abv-calculator-thumbnail.jpg" />
      </Helmet>

      <Box sx={{ padding: '20px', minHeight: '100vh', maxWidth: '1200px', display: 'flex', flexDirection: 'column', gap: '20px', backgroundColor: '#FAF9F1' }}>
        {/* Header Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #ccc',
            paddingBottom: '10px',
            flexDirection: { xs: 'column-reverse', sm: 'row' }, // Adjust flex direction based on screen size
            gap: { xs: '10px', sm: '0' }, // Add gap for small screens
            '@media (max-width: 550px)': {
              flexDirection: 'column-reverse', // Dropdown above headline on small screens
              alignItems: 'flex-end', // Align dropdown to the right
            },
            '@media (max-width: 600px) and (min-width: 551px)': {
              flexDirection: 'row', // Keep the dropdown and headline in a row
              justifyContent: 'space-between', // Ensure they are spaced correctly
            },
          }}
        >
          <Typography variant="h3" sx={{ textAlign: 'left' }}>ABV Calculator</Typography>
          <Select
            value={unit}
            onChange={(e) => (e.target.value === 'Metric' ? switchToMetric() : switchToImperial())}
            variant="outlined"
            size="small"
            sx={{ width: '150px' }}
          >
            <MenuItem value="Metric">Metric</MenuItem>
            <MenuItem value="Imperial">Imperial</MenuItem>
          </Select>
        </Box>

        {/* Introduction */}
        <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: '16px' }}>
          Calculate the Alcohol By Volume (ABV) of your brew with ease using our comprehensive calculator. This tool supports both
          <strong> Specific Gravity (SG)</strong> and <strong>Plato</strong> measurements, giving you flexibility in how you input your data.
          Simply measure your wort using a <strong>hydrometer</strong> or <strong>refractometer</strong>, and ensure your hydrometer is calibrated
          to the correct temperature, typically 20°C (68°F). If your measurements are taken at a different temperature, use our
          <a href="/tools/calculators/hydro-temp-correct" style={{ color: '#4c9a2a', textDecoration: 'underline' }}> Hydrometer Temperature Correction Calculator </a>
          to adjust your readings accurately.
        </Typography>

        {/* Main Layout */}
        <Box sx={{ display: 'flex', gap: '20px', flex: '1', flexDirection: { xs: 'column', sm: 'row' } }}>

          <Box
            sx={{
              flex: '1 1 30%',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              padding: '10px',
              paddingTop: '45px',
              borderRadius: '8px',
            }}
          >
            <Select
              value={gravityScale}
              onChange={(e) => setGravityScale(e.target.value)}
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
              defaultValue="Specific Gravity"
            >
              <MenuItem value="Specific Gravity">Specific Gravity (SG 1.xxx)</MenuItem>
              <MenuItem value="Plato">Plato (°P)</MenuItem>
            </Select>
          </Box>

          <Box
            sx={{
              flex: '1 1 70%',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <Typography variant="body1">
              <b>Units:</b> {unit}; <b>Gravity Scale:</b> {gravityScale}
            </Typography>
            <StyledTextField
              label={gravityScale === 'Specific Gravity' ? 'Original Gravity (OG)' : 'Original Plato (°P)'}
              type="number"
              value={og}
              onChange={handleInputChange(setOg)}
              fullWidth
              variant="outlined"
            />
            <StyledTextField
              label={gravityScale === 'Specific Gravity' ? 'Final Gravity (FG)' : 'Final Plato (°P)'}
              type="number"
              value={fg}
              onChange={handleInputChange(setFg)}
              fullWidth
              variant="outlined"
            />


            <RadioGroup
              value={calculationType}
              onChange={(e) => setCalculationType(e.target.value)}
              row
            >
              <FormControlLabel
                value="standard"
                control={<Radio sx={{ color: '#333', '&.Mui-checked': { color: '#fabc18' } }} />}
                label="Standard"
              />
              <FormControlLabel
                value="alternate"
                control={<Radio sx={{ color: '##333', '&.Mui-checked': { color: '#fabc18' } }} />}
                label="Alternate"
              />
            </RadioGroup>
            <StyledButton onClick={calculateResults}>
              Calculate
            </StyledButton>
          </Box>
        </Box>


        <Box sx={{ marginTop: '40px' }}>
          <Divider sx={{ marginBottom: '20px' }} />
          <Typography variant="h5">Results</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#FAF9F1', fontWeight: 'bold' }}>Metric</TableCell>
                  <TableCell sx={{ color: '#FAF9F1', fontWeight: 'bold' }}>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>ABV</TableCell>
                  <TableCell>{results.abv}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Apparent Attenuation</TableCell>
                  <TableCell>{results.apparentAttenuation}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Calories</TableCell>
                  
                  <TableCell>
                    {results.calories} {unit === 'Metric' ? 'Cal per 100ml' : 'Cal per 12 oz'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>OG (Gravity) | OG (°P)</TableCell>
                  <TableCell>  {results.og.gravity} SG   |   {(259 - (259 / parseFloat(results.og.gravity))).toFixed(2)}°P
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>FG (Gravity) FG (°P)</TableCell>
                  <TableCell>  {results.fg.gravity} SG   |   {(259 - (259 / parseFloat(results.fg.gravity))).toFixed(2)}°P
                  </TableCell>
                </TableRow>

              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/* Additional Content */}
        <Box sx={{ marginTop: '40px', maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto' }}>
          {/* Main Section Headline */}
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              marginBottom: '16px',
              color: '#2b2d42',
              textAlign: 'center',
            }}
          >
            Understanding Beer ABV: The Role of OG and FG
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: '16px', lineHeight: '1.6', paddingLeft: '16px', textAlign: 'justify' }}
          >
            Calculating the Alcohol by Volume (ABV) of beer is an essential skill for brewers. ABV indicates the percentage of
            alcohol in your finished brew and is derived from two key measurements: Original Gravity (OG) and Final Gravity (FG).
            OG measures the sugar concentration in the wort before fermentation, while FG reflects how much sugar remains
            after fermentation. The difference between these values determines the amount of alcohol produced during the brewing
            process, influencing the beer's strength and flavor profile.
          </Typography>

          {/* ABV Formulas */}
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              marginBottom: '8px',
              paddingLeft: '16px',
              color: '#4c9a2a',
            }}
          >
            Calculating ABV: The Formulas
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '32px', textAlign: 'justify' }}>
            Brewers have two primary formulas to calculate ABV:
          </Typography>
          <Typography variant="body2" sx={{ paddingLeft: '48px', marginBottom: '16px' }}>
            <strong>1. Standard ABV Formula:</strong> ABV = (OG – FG) * 131.25
          </Typography>
          <Typography variant="body2" sx={{ paddingLeft: '48px', marginBottom: '16px' }}>
            <strong>2. Alternate ABV Formula:</strong> ABV = (76.08 * (OG - FG) / (1.775 - OG)) * (FG / 0.794)
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: '16px', lineHeight: '1.6', paddingLeft: '32px', textAlign: 'justify' }}
          >
            The standard formula is widely used for its simplicity and reliability. The alternate formula adds a layer of precision
            for brewers who want a more detailed calculation based on additional variables. Use the method that suits your brewing
            needs best—our calculator supports both.
          </Typography>

          {/* Additional Formulas */}
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              marginBottom: '8px',
              paddingLeft: '16px',
              color: '#4c9a2a',
            }}
          >
            Additional Brewing Formulas
          </Typography>

          {/* Apparent Attenuation */}
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              marginBottom: '8px',
              paddingLeft: '32px',
              color: '#2b2d42',
            }}
          >
            Apparent Attenuation
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '48px', textAlign: 'justify' }}>
            Apparent attenuation measures the percentage of sugars converted into alcohol during fermentation. It indicates yeast
            efficiency and contributes to the final dryness of your beer. The formula is:
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: '16px', paddingLeft: '64px' }}>
            <strong>Apparent Attenuation (%) = ((OG - FG) / (OG - 1)) * 100</strong>
          </Typography>

          {/* Calories */}
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              marginBottom: '8px',
              paddingLeft: '32px',
              color: '#2b2d42',
            }}
          >
            Calculating Calories
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '48px', textAlign: 'justify' }}>
            Estimating the calorie content of beer helps brewers understand its nutritional impact. The formula calculates calories
            based on alcohol content and residual sugars:
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: '16px', paddingLeft: '64px' }}>
            <strong>
              Calories per 100ml = (ABV * 2.5) + (FG - 1) * 1000 * 4
            </strong>
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '48px', textAlign: 'justify' }}>
            For imperial units (12oz servings), the calorie count can be adjusted accordingly.
          </Typography>

          {/* Specific Gravity to Plato */}
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              marginBottom: '8px',
              paddingLeft: '32px',
              color: '#2b2d42',
            }}
          >
            Converting Specific Gravity to Plato
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '48px', textAlign: 'justify' }}>
            Degrees Plato (°P) measure the sugar concentration in the wort as a percentage by weight. While Specific Gravity (SG)
            compares the density of the wort to water, Plato provides a more direct percentage. The conversion formula is:
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: '16px', paddingLeft: '64px' }}>
            <strong>°P = 259 - (259 / SG)</strong>
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '48px', textAlign: 'justify' }}>
            This conversion simplifies communication between brewers in different regions and is crucial for accurate calculations
            when working with Plato-based recipes.
          </Typography>





          {/* Advanced Calculator Features */}
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              marginTop: '32px',
              marginBottom: '16px',
              color: '#2b2d42',
              textAlign: 'center',
            }}
          >
            Advanced Features of This Calculator
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
            This calculator offers flexibility in input formats, supporting both specific gravity (SG) and Plato. Additionally, it
            calculates apparent attenuation, calories, and sugar concentrations in both scales. While the ABV equations focus
            on alcohol content, the calorie calculations use a separate method, ensuring independent and accurate results.
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}
          >
            Don't forget to adjust your readings based on temperature when using a hydrometer. If you're using a refractometer,
            our dedicated calculator ensures precise post-fermentation readings.
          </Typography>

          {/* Essential Brewing Terms */}
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              marginTop: '32px',
              marginBottom: '16px',
              color: '#2b2d42',
              textAlign: 'center',
            }}
          >
            Essential Brewing Terms: A Quick Guide
          </Typography>

          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              marginBottom: '8px',
              paddingLeft: '16px',
              color: '#2b2d42',
            }}
          >
            Original Gravity (OG)
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '32px', textAlign: 'justify' }}>
            The density of sugars in the wort before fermentation begins. A higher OG suggests a stronger beer with more alcohol
            potential. It's a critical starting point for monitoring the brewing process.
          </Typography>

          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              marginBottom: '8px',
              paddingLeft: '16px',
              color: '#2b2d42',
            }}
          >
            Final Gravity (FG)
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '32px', textAlign: 'justify' }}>
            The density of the beer after fermentation is complete. FG reflects the remaining sugars and helps determine how dry
            or sweet the final product will be. Together with OG, FG allows you to calculate the ABV.
          </Typography>

          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              marginBottom: '8px',
              paddingLeft: '16px',
              color: '#2b2d42',
            }}
          >
            Apparent Attenuation
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '32px', textAlign: 'justify' }}>
            This term describes the percentage of sugars converted into alcohol during fermentation. It indicates yeast efficiency
            and contributes to the overall dryness or sweetness of the beer.
          </Typography>

          {/* Tips for Better Results */}
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              marginTop: '32px',
              marginBottom: '16px',
              color: '#2b2d42',
              textAlign: 'center',
            }}
          >
            Tips for Accurate Brewing Measurements
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '16px', paddingLeft: '16px', textAlign: 'justify' }}>
            Always calibrate your hydrometer for temperature and double-check your readings for accuracy. Use our temperature
            adjustment calculator for better precision. For small samples, refractometers provide a convenient alternative,
            but ensure you use the proper correction formulas post-fermentation.
          </Typography>
        </Box>

      </Box>
    </div>
  );
};

export default ABVCalculator;
